import React from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, DedicationsSelectors } from "../state";
import { validateEmail } from "../lib";
import { useMobile } from "../themes";

function _DonorDetails(props) {
  const isMobile = useMobile();
  const {
    globalItems: {
      handleErrorOutline,
      handleBlur,
      handleFocus,
      handleDonorChange,
    } = {},
    donorRef,
  } = props;

  const donor = useSelector(DedicationsSelectors.donor);

  const useStyles = makeStyles(theme => ({
    addAdditionalName: {
      backgroundColor: "#D8D8D8",
      color: "#1D78B9",
      padding: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: 18,
      fontWeight: 700,
      marginTop: 20,
      width: "65%",
      "&:hover": {
        opacity: 0.7,
      },
    },
    label: {
      backgroundColor: "white",
    },
    textFieldWrapper: {
      display: "flex",
      justifyContent: "space-between",
      flexFlow: "row wrap",
      [`& .MuiTextField-root`]: {
        marginBottom: 10,
      },
    },
    textField: {
      marginBottom: 5,
      minWidth: isMobile ? "49%" : 225,
      maxWidth: "49%",
      [`& fieldset`]: {
        borderRadius: 0,
      },
    },
    inputLabel: {
      paddingLeft: 18.5,
    },
    headers: {
      display: "block",
      textAlign: "center",
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <h2 className={classes.headers} ref={donorRef}>
        Payment Details
      </h2>
      <div className={classes.textFieldWrapper}>
        <TextField
          className={classes.textField}
          name="first_name"
          autoComplete="given-name"
          required
          error={handleErrorOutline("first_name")}
          onBlur={e => handleBlur(e)}
          onFocus={e => handleFocus(e)}
          onChange={handleDonorChange}
          label="First Name"
          type="search"
          variant="outlined"
          value={donor.first_name}
        />
        <TextField
          className={classes.textField}
          name="last_name"
          autoComplete="family-name"
          required
          error={handleErrorOutline("last_name")}
          onBlur={e => handleBlur(e)}
          onFocus={e => handleFocus(e)}
          onChange={handleDonorChange}
          label="Last Name"
          type="search"
          variant="outlined"
          value={donor.last_name}
        />
        <TextField
          className={classes.textField}
          name="phone"
          autoComplete="tel"
          required
          error={handleErrorOutline("phone")}
          onBlur={e => handleBlur(e)}
          onFocus={e => handleFocus(e)}
          onChange={handleDonorChange}
          label="WhatsApp Number"
          type="number"
          variant="outlined"
          value={donor.phone}
        />
        <TextField
          className={classes.textField}
          name="email"
          autoComplete="email"
          required
          error={handleErrorOutline("email")}
          helperText={
            donor.email &&
            !validateEmail(donor.email) &&
            "Please enter a valid email address"
          }
          onBlur={e => handleBlur(e)}
          onFocus={e => handleFocus(e)}
          onChange={handleDonorChange}
          label="Email Address"
          type="search"
          variant="outlined"
          value={donor.email}
        />
      </div>
    </div>
  );
}
export const DonorDetails = _DonorDetails;
