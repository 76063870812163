import React, { useRef, useEffect } from "react";
import EmailEditor from "react-email-editor";
import { useHistory } from "react-router-dom";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import Tooltip from "@material-ui/core/Tooltip";

import {
  EmailEditorActions,
  useDispatch,
  useSelector,
  EmailEditorSelectors,
} from "../../../state";

/**
 * Callback when checking if the editor contents is valid
 * @callback EmailEditorValidCallback
 * @param {boolean}
 */

/**
 *
 * @param {Object} props
 * @param {EmailEditorValidCallback} props.onValidation
 */
export const EmailEditorComponent = props => {
  const { onValidation } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * @type {React.MutableRefObject<EmailEditor>}
   */
  const editorRef = useRef(undefined);

  const loading = useSelector(EmailEditorSelectors.isLoading);
  const uploading = useSelector(EmailEditorSelectors.isUploading);
  const serverUpToDate = useSelector(EmailEditorSelectors.serverUpToDate);
  const reduxEditorDesign = useSelector(EmailEditorSelectors.editorDesign);
  const dataInitialized = useSelector(EmailEditorSelectors.dataInitialized);
  const templateId = useSelector(EmailEditorSelectors.templateId);
  const templateSecret = useSelector(EmailEditorSelectors.templateSecret);
  const apiError = useSelector(EmailEditorSelectors.error);

  /**
   * Template ID currently in the url query `emailId`
   */
  const emailIdParam = new URLSearchParams(history.location.search).get(
    "emailId",
  );
  /**
   * Template secret currently in the url query `secret`
   */
  const emailSecretParam = new URLSearchParams(history.location.search).get(
    "secret",
  );

  useEffect(() => {
    console.log({ emailId: emailIdParam, emailSecret: emailSecretParam });
    if (emailIdParam) {
      dispatch(
        EmailEditorActions.loadExistingEmailTemplate({
          id: emailIdParam,
          secret: emailSecretParam,
        }),
      );
      console.log("loading existing");
    } else {
      dispatch(EmailEditorActions.createNewEmailTemplate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const syncTemplateIdAndSecretWithURL = () => {
    if (templateId !== emailIdParam) {
      const search = new URLSearchParams(history.location.search);

      search.set("emailId", templateId);
      search.set("secret", templateSecret);

      history.replace({
        pathname: history.location.pathname,
        search: `?${search.toString()}`,
      });
    }
  };

  const onLoadEditor = () => {
    // This function only loads once we have created a new template
    // or downloaded an existing one. Make sure the template id and
    // secret is synced with the url
    syncTemplateIdAndSecretWithURL();

    // When the component reloads for a second time, this function is
    // called before the editor ref contains the EmailEditor component.
    // Therefore, we keep checking if it's loaded before attaching
    // the event listener or loading the design
    // https://github.com/unlayer/react-email-editor/issues/22
    // https://github.com/unlayer/react-email-editor/issues/100#issuecomment-775078339
    let loopsLeft = 10;
    const intervalTimer = setInterval(() => {
      if (editorRef.current) {
        editorRef.current.addEventListener(
          "design:updated",
          updateChangeInBackend,
        );

        console.log({ loading: reduxEditorDesign });
        if (reduxEditorDesign) {
          onValidation(true);
        } else {
          onValidation(false);
        }
        editorRef.current.loadDesign(reduxEditorDesign);

        clearInterval(intervalTimer);
      }

      if (loopsLeft === 0) {
        clearInterval(intervalTimer);
      }

      loopsLeft -= 1;
    }, 500);
  };

  const updateChangeInBackend = async () => {
    editorRef.current.exportHtml(async data => {
      const { design, html } = data;

      if (design) {
        onValidation(true);
      } else {
        onValidation(false);
      }

      dispatch(EmailEditorActions.updateEmailTemplate({ design, html }));
    });
  };

  return (
    <div>
      <div
        style={{
          padding: 15,
        }}
      >
        <Tooltip title="Sync status">
          {serverUpToDate ? (
            <CloudDoneIcon />
          ) : uploading && !apiError.error ? (
            <CloudUploadIcon />
          ) : loading ? (
            <CloudDownloadIcon />
          ) : (
            <CloudOffIcon />
          )}
        </Tooltip>
      </div>

      {dataInitialized && (
        <EmailEditor
          style={{ height: "90vh" }}
          ref={editorRef}
          onLoad={onLoadEditor}
        />
      )}
    </div>
  );
};
