import React from "react";
const summaryItem = {
  fontSize: { fontSize: 14 },
  nameFontFamily: { fontFamily: ".SF NS Display" },
  amountFontFamily: { fontFamily: "Lato" },
  amountColor: { color: "#1D78B9" },
  nameWidth: { width: "75%" },
  amountWidth: { width: "25%" },
  amountFontWeight: { fontWeight: "bold" },
};
const st = {
  width: "50%",
  borderRight: "1px solid black",
  display: "inline-flex",
};
export const LineItem = function(props) {
  const { text, regPrice, price } = props;
  return (
    <div>
      <div style={{ ...st, ...summaryItem.nameWidth }}>
        <span
          style={{
            ...summaryItem.fontSize,
            ...summaryItem.nameFontFamily,
          }}
        >
          {text}
        </span>
      </div>
      <div
        style={{
          ...st,
          ...summaryItem.amountWidth,
          borderRight: "unset",
          justifyContent: "flex-end",
        }}
      >
        <span
          style={{
            ...summaryItem.amountColor,
            ...summaryItem.amountFontFamily,
            ...summaryItem.fontSize,
            ...summaryItem.fontWeight,
          }}
        >
          {regPrice && (
            <p
              style={{
                textDecoration: "line-through",
                display: "inline",
                marginRight: 20,
              }}
            >
              ${regPrice}
            </p>
          )}
          ${price}
        </span>
      </div>
    </div>
  );
};
