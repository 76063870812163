export const doseStatusAndMessagePackageAmount = 600;
export const messageAmount = 200;
export const statusAmount = 500;
export const emailAmount = 1500;

export class Cart {
  constructor(donor) {
    this.donor = donor;
  }

  getMessageCount = () => {
    return (this.donor.is_message && this.donor.message_dates.length) || 0;
  };
  getStatusCount = () => {
    return (this.donor.is_status && this.donor.status_dates.length) || 0;
  };
  getEmailCount = () => {
    return this.donor.email_dates.length || 0;
  };
  getMessageAndStatusPackageAmount = () => {
    return this.getMessageCount() < this.getStatusCount()
      ? this.getMessageCount()
      : this.getStatusCount();
  };
  // returns amount of messages that are not part of package deal
  getExtraMessageCount = () => {
    return this.getMessageCount() - this.getStatusCount() > 0
      ? this.getMessageCount() - this.getStatusCount()
      : 0;
  };
  // returns amount of statuses that are not part of package deal
  getExtraStatusCount = () => {
    return this.getStatusCount() - this.getMessageCount() > 0
      ? this.getStatusCount() - this.getMessageCount()
      : 0;
  };
  getFullPriceAmount = () => {
    return (
      this.getMessageCount() * messageAmount +
      this.getStatusCount() * statusAmount +
      this.getEmailCount() * emailAmount +
      Number(this.donor.additional_amount ?? 0)
    );
  };
  getPrediscountAmount = () => {
    const amount =
      this.getMessageAndStatusPackageAmount() *
      doseStatusAndMessagePackageAmount +
      this.getExtraMessageCount() * messageAmount +
      this.getExtraStatusCount() * statusAmount +
      this.getEmailCount() * emailAmount +
      Number(this.donor.additional_amount ?? 0);
    return amount;
  };
  getDiscountAmount = (amount = this.getPrediscountAmount()) => {
    let discountedAmount = 0;
    if (this.donor.discount_code_id) {
      if (this.donor.discount.type === "amount") {
        discountedAmount = this.donor.discount.amount;
      } else if (this.donor.discount.type === "percent") {
        discountedAmount =
          (amount - (this.donor.additional_amount || 0)) *
          this.donor.discount.amount *
          0.01;
      }
    }
    return discountedAmount;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  getTotalAmount = () => {
    const prediscountAmount = this.getPrediscountAmount();
    const amount =
      prediscountAmount - this.getDiscountAmount(prediscountAmount);

    console.log({ prediscountAmount, amount });
    return amount > 0 ? amount : 1;
    // The minimum total is $1. If it is $0, we need to rewrite the 
    // flow to not try to checkout with a credit card. Stripe will
    // throw an error if we try with $0.
  };

  /**
   * Check if the cart is empty
   *
   * @returns {boolean} If the cart is empty
   */
  cartIsEmpty = () => {
    return (
      this.getMessageCount() + this.getStatusCount() + this.getEmailCount() < 1
    );
  };
}
