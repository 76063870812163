import React, { useMemo, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Dialog, CircularProgress } from "@material-ui/core";
import useResponsiveFontSize from "../../lib/useResponsiveFontSize";
import "../../assets/css/stripe.css";
import { useMobile } from "../../themes";
import { updateDonorApiCall } from "../../lib";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Close } from "@material-ui/icons";
import axios from "axios";

import {
  DedicationsSelectors,
  DedicationActions,
  useSelector,
  useDispatch,
} from "../../state";
const { REACT_APP_API_URL } = process.env;
const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.015em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize],
  );

  return options;
};

const errorToast = {
  type: "error",
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
};
const CardForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const donor = useSelector(DedicationsSelectors.donor);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    const {
      first_name,
      last_name,
      email,
      address_line_1,
      address_line_2,
      city,
      state,
      zip,
      country,
      phone,
      clientSecret,
    } = donor;

    if (donor.lecture_id) {
      // setting to complete if lecture sponsor to ensure that still available for sponsor (there's a unique constraint on the lecture_id with a payment status of complete).
      // if there are any other errors processing the transaction, the payment status should then be changed back to either declined or error. need to keep in mind to check in case something ever gets stuck at complete even though there was an error, that lecture will be locked.
      const res = await axios
        .create({
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        })
        .get(`/lecture-is-sponsored/${donor.lecture_id}`);
      if (res.data.isSponsored) {
        dispatch(DedicationActions.setLectureAlreadySponsored(true));
        return;
      }
    }
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          address: {
            city,
            country,
            line1: address_line_1,
            line2: address_line_2,
            postal_code: zip,
            state,
          },
          email,
          name: `${first_name} ${last_name}`,
          phone,
        },
      },
    });
    if (payload?.error?.code === "card_declined") {
      toast("Your card was declined.", errorToast);
      updateDonorApiCall(donor, "declined");
      setLoading(false);
      return;
    }
    if (payload?.paymentIntent?.status === "succeeded") {
      const res = await updateDonorApiCall(donor);
      if (
        res.data.status === "error" &&
        res.data.message === "Lecture was already sponsored!"
      ) {
        toast(
          "Someone sponsored this lecture at the same time and beat you to it. Please contact support for a refund.",
          errorToast,
        );
        setLoading(false);
        return;
      }
      dispatch(DedicationActions.setStripeOpen(false));
      dispatch(DedicationActions.setDonateSuccess(true));
    } else {
      updateDonorApiCall(donor, "payment error");
      toast("An error occurred.", errorToast);
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    dispatch(DedicationActions.setStripeOpen(false));
    updateDonorApiCall(donor, "user cancelled");
  };

  return (
    <div
      style={{
        width: isMobile ? 270 : 400,
        margin: isMobile ? 10 : 20,
      }}
    >
      <ToastContainer />
      <form onSubmit={handleSubmit} style={{ fontSize: 12 }}>
        <label>
          Card details
          <Close
            style={{ float: "right", cursor: "pointer" }}
            onClick={handleCloseModal}
          />
          <CardElement
            options={{
              ...options,
              value: { postalCode: donor.zip },
            }}
          />
        </label>
        <button
          type="submit"
          disabled={!stripe || loading}
          style={{ width: "100%" }}
        >
          {loading ? <CircularProgress style={{ padding: 6 }} /> : "Donate"}
        </button>
      </form>
    </div>
  );
};

export const StripeWrapper = () => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  );
  const stripeOpen = useSelector(DedicationsSelectors.stripeOpen);
  return (
    <Dialog open={stripeOpen}>
      <Elements stripe={stripePromise}>
        <CardForm style={{ width: "100%" }} />
      </Elements>
    </Dialog>
  );
};
