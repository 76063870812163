import React, { useState, useEffect } from "react";
import { Button, TextField } from "@material-ui/core";
// Local
import {
  useSelector,
  useDispatch,
  DedicationsSelectors,
  DedicationActions,
} from "../../state";
import { useMobile } from "../../themes";
import ReactGA from "react-ga";
import { authPost } from "../../lib";
import { validateEmail } from "../../lib";
const { REACT_APP_IMAGE_PATH } = process.env;

function _ShiurThankyouPage() {
  const donor = useSelector(DedicationsSelectors.donor);
  const dispatch = useDispatch();
  const isMobile = useMobile();

  const [showSentMessage, setShowSentMessage] = useState("");

  const handleShareAnalytics = sharedVia => {
    ReactGA.event({
      category: "Lecture Sponsor Completed",
      action: "share",
      label: `${sharedVia}`,
    });
  };
  const sendEmails = async () => {
    setShowSentMessage("");
    await authPost("/lecture-sponsor/notify-others", { donor }).then(r => {
      if (r.data.success) {
        setShowSentMessage("Your email was sent");
        dispatch(
          DedicationActions.setDonor({ ...donor, share_email_addresses: "" }),
        );
      } else {
        setShowSentMessage("Error sending email");
      }
      // toast("Your email was sent", {
      //   type: "success",
      //   position: "bottom-center",
      // });
    });
  };

  useEffect(() => {
    dispatch(
      DedicationActions.setDonor({
        ...donor,
        display_name: `${donor.first_name} ${donor.last_name}`.trim(),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // using object for now. should switch to class
  const iconStyle = {
    margin: 8,
    cursor: "pointer",
    height: 44,
    width: 44,
  };
  // if page was reloaded.
  if (!donor.email) {
    window.location.href = "https://torahanytime.com";
  }
  const lectLink = `https://www.torahanytime.com/#/lectures?v=${donor.lecture.id}`;
  let body = encodeURIComponent(
    `Check out this Torah class I just sponsored on TorahAnytime ${donor.lecture.title} by ${donor.lecture.speaker_title} ${donor.lecture.speaker_name_first} ${donor.lecture.speaker_name_last} ${lectLink} #Torah #Torahanytime #Judasim`,
  );
  return (
    <div
      style={{
        textAlign: "center",
        minHeight: `calc(100vh - ${isMobile ? 113 : 144}px)`,
      }}
    >
      <img
        src="https://www.torahanytime.com/static/images/logo.png"
        alt=""
        style={{ marginTop: isMobile ? 30 : 100 }}
      />
      <br />
      <h1
        style={{
          color: "#1D78B9",
          fontFamily: "Lato",
          fontSize: 46,
        }}
      >
        Thank you for sharing Torah with others!
      </h1>
      <h1
        style={{
          color: "#1D78B9",
          fontFamily: "Lato",
          fontSize: 26,
        }}
      >
        Your sponsorship will appear in a few minutes.
      </h1>
      <hr
        style={{
          width: "20%",
          color: "#979797",
        }}
      />
      <h1
        style={{
          color: "#1D78B9",
          fontFamily: "Lato",
          fontSize: 36,
        }}
      >
        Let others know about your sponsorship
      </h1>
      <div>
        <TextField
          name="display_name"
          style={{
            width: "30%",
          }}
          onChange={e => {
            dispatch(
              DedicationActions.setDonor({
                ...donor,
                display_name: e.target.value,
              }),
            );
          }}
          label="Your Name"
          type="search"
          variant="outlined"
          value={donor.display_name}
        />
      </div>
      <br />
      <TextField
        name="share_email_addresses"
        style={{
          width: "30%",
        }}
        placeholder="Enter email addresses separated by commas"
        onChange={e => {
          const val = e.target.value.replace(/ /g, "");
          dispatch(
            DedicationActions.setDonor({
              ...donor,
              share_email_addresses: val,
            }),
          );
          if (val && showSentMessage) {
            setShowSentMessage("");
          }
        }}
        label="Email Addresses"
        type="search"
        variant="outlined"
        value={donor.share_email_addresses}
      />
      <br />
      {showSentMessage && <p>{showSentMessage}</p>}
      <Button
        style={{
          backgroundColor: "#E07020",
          color: "#FFFFFF",
          paddingRight: 50,
          paddingLeft: 50,
          marginTop: 10,
        }}
        disabled={
          !donor.share_email_addresses ||
          donor.share_email_addresses.split(",").filter(e => !validateEmail(e))
            .length > 0
        }
        onClick={sendEmails}
      >
        <span style={{ fontSize: 16 }}>Send</span>
      </Button>
      <br />
      <div
        style={{
          paddingTop: 100,
        }}
      >
        <a
          href={`https://www.facebook.com/sharer/sharer.php?quote=${body}&u=torahanytime.com`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleShareAnalytics("facebook")}
        >
          <img
            style={iconStyle}
            alt=""
            src={`${REACT_APP_IMAGE_PATH}/share_facebook.png`}
          />
        </a>
        <a
          href={`https://twitter.com/intent/tweet?text=${body}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleShareAnalytics("twitter")}
        >
          <img
            style={iconStyle}
            alt=""
            src={`${REACT_APP_IMAGE_PATH}/share_twitter.png`}
          />
        </a>
        <a
          href={`https://wa.me?text=${body}`}
          target="_blank"
          rel="noopener noreferrer"
          data-action="share/whatsapp/share"
          onClick={() => handleShareAnalytics("whatsapp")}
        >
          <img
            style={iconStyle}
            alt=""
            src={`${REACT_APP_IMAGE_PATH}/share_whatsapp.png`}
          />
        </a>
      </div>
    </div>
  );
}

export const ShiurThankyouPage = React.memo(_ShiurThankyouPage);
