import { TextField, Link } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { TextFieldWithCharLimit, MultiDateSelect } from "../../components";
import {
  useSelector,
  DedicationsSelectors,
  useDispatch,
  DedicationActions,
} from "../../state";
import axios from "axios";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { Transition } from "react-transition-group";

const { REACT_APP_API_URL } = process.env;

require("date-time-format-timezone");

export const DailyDoseMessage = function({ style, show, setPreviewOpen }) {
  const [disabledDates, setDisabledDates] = useState(null);
  const donor = useSelector(DedicationsSelectors.donor);

  const setDonor = v =>
    dispatch(DedicationActions.setDonor({ ...donor, ...v }));

  const dispatch = useDispatch();

  const useStyles = makeStyles(theme => ({
    previewLink: {
      textAlign: "right",
      cursor: "pointer",
    },
    message: {
      marginLeft: 14,
      fontSize: 12,
      marginBottom: 10,
      position: "relative",
      top: -5,
      opacity: 0.54,
    },
  }));
  const classes = useStyles();
  useEffect(() => {
    axios
      .create({
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      })
      .get(`/corporate-message-dates`)
      .catch(err => {
        toast("An error occurred!", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
        throw err;
      })
      .then(result => setDisabledDates(new Set(result.data)));
  }, []);
  const defaultStyle = {
    ...style,
    padding: 5,
    height: 0,
    overflow: "hidden",
    transition: `height 500ms ease-in-out`,
  };
  const transitionStyles = {
    entering: { height: 320, padding: 5 },
    entered: { height: "auto" },
    exiting: { height: 0 },
    exited: { height: 0, padding: 0 },
  };

  return (
    <Transition in={show} timeout={500}>
      {state => (
        <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
          <div>
            <div className={classes.previewLink}>
              <Link onClick={_ => setPreviewOpen(true)}>Preview Sample</Link>
            </div>
            <TextFieldWithCharLimit
              style={{ width: "100%" }}
              label="Message"
              maxLength={70}
              muiInputBaseStyles={{ maxHeight: 60 }}
              value={donor.message_text}
              onChange={e =>
                setDonor({
                  ...donor,
                  message_text: e.target.value,
                })
              }
              multiline
              rows={5}
              margin="normal"
              variant="outlined"
            />
            <div className={classes.message}>
              Wrap text you want bold with an asterisk (*)
            </div>
            <div>
              <TextField
                style={{ width: "100%", marginLeft: 0 }}
                label="Link"
                value={donor.link_to_action}
                variant="outlined"
                onChange={e => setDonor({ link_to_action: e.target.value })}
                helperText="A short link will be generated for the URL entered"
              />
            </div>
            {disabledDates && (
              <MultiDateSelect
                donorDatesPropName="message_dates"
                globalItems={{ donor, setDonor }}
                disabledDates={disabledDates}
                note={
                  <p
                    style={{
                      textAlign: "center",
                      padding: "0 20px",
                      margin: 0,
                    }}
                  >
                    Dates that are not selectable are taken
                  </p>
                }
              />
            )}
          </div>
        </div>
      )}
    </Transition>

    //    TODO: call to action link stuff
  );
};
