import React from "react";
import { HighlightOff } from "@material-ui/icons";

import { DedicationActions, useDispatch } from "../../state";
import { cleanLabel } from "../../lib/utils";

function _DedicationSummary({
  dedication,
  index,
  handleDelete,
  getFirstSecond,
  ...props
}) {
  const dispatch = useDispatch();
  const date = new Date(dedication.day);
  return (
    <div
      style={{
        marginTop: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          padding: 10,
          backgroundColor: "#FFF",
          border: "2px solid #F0F0F0",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontStyle: "italic",
            }}
          >
            {cleanLabel(dedication.dedicationType?.label)}
          </div>
          <div>
            {date.getMonth() + 1}/{date.getDate()}
          </div>
        </div>
        <div>{dedication.name} </div>
      </div>
      <HighlightOff
        style={{ cursor: "pointer", color: "lightgray", marginLeft: 2 }}
        onClick={() => dispatch(DedicationActions.removeDedication(index))}
      />
    </div>
  );
}

export const DedicationSummary = React.memo(_DedicationSummary);
