import React, { useState, useEffect, useRef } from "react";
import { useMobile } from "../../themes";
import { donorIsValid } from "../../lib";
import { validateEmail } from "../../lib";
import NumberFormat from "react-number-format";
import { Button, TextField } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StripeWrapper } from "../../components/common/Stripe";
import { useParams } from "react-router-dom";
import { SelectionCard, SelectStateUS } from "../../components";
import {
  useDispatch,
  useSelector,
  DedicationsSelectors,
  DedicationActions,
} from "../../state";
import { SelectCountry } from "../../components/inputs/SelectCountry";
import { Redirect } from "react-router-dom";

const { REACT_APP_API_URL } = process.env;

function _ShiurPage() {
  const dispatch = useDispatch();
  const donor = useSelector(DedicationsSelectors.donor);
  const useStyles = makeStyles(_theme => ({
    addAdditionalName: {
      backgroundColor: "#D8D8D8",
      color: "#1D78B9",
      // margin: 3,
      padding: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: 18,
      fontWeight: 700,
      marginTop: 20,
      width: "65%",
      "&:hover": {
        opacity: 0.7,
      },
    },
    label: {
      backgroundColor: "white",
    },
    textField: {
      [`& fieldset`]: {
        borderRadius: 0,
      },
    },
    twoFields: {
      display: "flex",
      justifyContent: "space-between",
    },
    inputLabel: {
      paddingLeft: 18.5,
    },
    headers: {
      display: "block",
      // textAlign: "center",
    },
    dedicationTypesDiv: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
  }));
  const classes = useStyles();

  const [textFieldsTouched, setTextFieldsTouched] = useState([]);
  const [showErrorFields, setShowErrorFields] = useState(false);
  const [lecture, setLecture] = useState({});
  const [activeDedicationTypeCard, setActiveDedicationTypeCard] = useState({});
  const [customDedicationType, setCustomDedicationType] = useState(false);
  const [sponsoredByText, setSponsoredByText] = useState("");
  const [sponsoredForText, setSponsoredForText] = useState("");
  const [fullText, setFullText] = useState("");

  const { lectureId: lecture_id } = useParams();
  const dedicationTypes = [
    {
      // icon_string: "memorial.png",
      name: "memorial",
      label: "Leilui Nishmat <br>  In Memory Of",
      fill_text: "L’iluy nishmas/in memory of",
    },
    {
      // icon_string: "baby.png",
      name: "children",
      label: "Blessing for<br>Having Children",
      fill_text: (byText, forText) =>
        `Sponsored by ${byText} for ${forText} as a merit to have a child`,
    },
    {
      // icon_string: "shidduch.png",
      name: "shidduch",
      label: "Shidduchim <br> Finding Soulmate",
      fill_text: (byText, forText) =>
        `Sponsored by ${byText} for ${forText} as a merit to find a shidduch`,
    },
    {
      // icon_string: "refuah.png",
      name: "refuah",
      label: "Refuah Shelema <br>  Speedy Recovery",
      fill_text: "As a Zechus for a refuah sheleimah for",
    },
    {
      // name: ,
      label: "In Honor Of",
      fill_text: "In Honor of",
    },
    {
      // icon_string: "more.svg",
      name: "more",
      label: "Custom",
      fill_text: "",
    },
  ];
  useEffect(() => {
    var s = document.createElement("script");
    s.setAttribute("src", "https://js.stripe.com/v3/");
    document.body.appendChild(s);
  }, []);

  useEffect(() => {
    if (activeDedicationTypeCard.name !== "more") {
      const text =
        typeof activeDedicationTypeCard.fill_text === "function"
          ? activeDedicationTypeCard.fill_text(
              sponsoredByText,
              sponsoredForText,
            )
          : `Sponsored by ${sponsoredByText || "Anonymous"}${
              activeDedicationTypeCard.fill_text !== undefined
                ? " - " + activeDedicationTypeCard.fill_text
                : activeDedicationTypeCard.name
                ? " - " + activeDedicationTypeCard.name
                : sponsoredForText
                ? " for"
                : ""
            } ${sponsoredForText}`;
      setFullText(text);
      dispatch(DedicationActions.setDonor({ ...donor, dedication_text: text }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDedicationTypeCard, dispatch, sponsoredByText, sponsoredForText]);

  useEffect(() => {
    axios
      .create({
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      })
      .get(`/lectures/${lecture_id}`)
      .then(result => {
        setLecture(result.data);
        dispatch(
          DedicationActions.setDonor({
            ...donor,
            lecture_id,
            lecture: result.data,
          }),
        );
      });

    axios
      .create({
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      })
      .get(`/lecture-is-sponsored/${lecture_id}`)
      .then(result => {
        if (result.data.isSponsored) {
          dispatch(DedicationActions.setLectureAlreadySponsored(true));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMobile = useMobile();
  const myRef = useRef(null);
  const dedicationRef = useRef(null);
  const donateSuccess = useSelector(DedicationsSelectors.donateSuccess);
  const lectureAlreadySponsored = useSelector(
    DedicationsSelectors.lectureAlreadySponsored,
  );

  const handleDonorChange = e => {
    const { name, value } = e.target;
    const updatedD = { ...donor };
    updatedD[name] = name !== "email" ? value : value.trim();
    dispatch(DedicationActions.setDonor(updatedD));
  };

  // TODO: fix up
  const handleDonateClick = async () => {
    // TODO: fix validation for this
    if (!donorIsValid(donor)) {
      setShowErrorFields(true);
      window.scrollTo(0, myRef.current.offsetTop);
      return;
    }
    const totalAmount = 72;

    await dispatch(
      // TODO: fix up
      DedicationActions.setDonor({
        ...donor,
        total_amount: totalAmount,
        additional_amount: donor.additional_amount || 0,
      }),
    );
    const response = await axios
      .create({
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      })
      // TODO: fix up
      .post("/lecture-sponsor", {
        donor: {
          ...donor,
          payment_status: "pending",
          total_amount: totalAmount,
          additional_amount: donor.additional_amount || 0,
        },
      })
      .catch(err => {
        toast("An error occurred!", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
        throw err;
      });

    dispatch(
      DedicationActions.setDonor({
        ...donor,
        id: response.data.donor_id,
        total_amount: totalAmount,
        additional_amount: donor.additional_amount || 0,
        // always stripe for now
        clientSecret: response.data.clientSecret,
      }),
    );
    dispatch(DedicationActions.setStripeOpen(true));
  };
  const handleBlur = e => {
    setTextFieldsTouched([...textFieldsTouched, e.target.name]);
  };

  const handleFocus = e => {
    setTextFieldsTouched(textFieldsTouched.filter(f => f !== e.target.name));
  };
  const handleErrorOutline = name => {
    return (
      (showErrorFields || textFieldsTouched.includes(name)) &&
      (name !== "state" || donor.country === "US") &&
      (!donor[name] || (name === "email" && !validateEmail(donor[name])))
    );
  };
  const handleDedicationTypeChange = dedicationType => {
    setActiveDedicationTypeCard(dedicationType);
    if (dedicationType.name === "more") {
      setCustomDedicationType(true);
    } else {
      setCustomDedicationType(false);
    }
  };

  const [initiated, setInitiated] = useState(false);
  // TODO: fix up
  useEffect(() => {
    if (!initiated && !donor.id && donorIsValid(donor)) {
      setInitiated(true);
      const totalAmount = 72;
      axios
        .create({
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        })
        .post("/lecture-sponsor", {
          donor: {
            ...donor,
            payment_status: "initiated",
            total_amount: totalAmount,
            additional_amount: donor.additional_amount || 0,
          },
        })

        .then(response => {
          dispatch(
            DedicationActions.setDonor({
              ...donor,
              id: response.data.donor_id,
            }),
          );
        })
        .catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donor.id, initiated, dispatch]);

  return lectureAlreadySponsored ? (
    <h1>Lecture is already sponsored!</h1>
  ) : (
    <div ref={dedicationRef} style={{ zoom: 0.7 }}>
      {donateSuccess && (
        <Redirect to={`/dose-sponsor/shiur-sponsor/thankyou`} />
      )}
      <div
        style={{
          margin: "0 auto",
        }}
      >
        <h2
          className={classes.headers}
          style={{
            textAlign: "center",
          }}
        >
          Sponsor {lecture.title} by {lecture.speaker_title}{" "}
          {lecture.speaker_name_first} {lecture.speaker_name_last}
        </h2>
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              width: "50%",
              display: "inline-block",
            }}
          >
            <h2
              className={classes.headers}
              ref={myRef}
              style={{
                marginLeft: 25,
                marginBottom: 0, // to align with the border right of previous div starting under these first titles and the titles aligning with each other
              }}
            >
              Donor Details
            </h2>
            <div
              style={{
                padding: 25,
                borderRight: "1px solid #979797",
              }}
            >
              <div className={classes.twoFields}>
                <TextField
                  className={classes.textField}
                  name="first_name"
                  autoComplete="given-name"
                  required
                  error={handleErrorOutline("first_name")}
                  onBlur={e => handleBlur(e)}
                  onFocus={e => handleFocus(e)}
                  style={{ width: "48%", marginTop: 6, marginBottom: 6 }}
                  onChange={handleDonorChange}
                  label="First Name"
                  type="search"
                  variant="outlined"
                  value={donor.first_name}
                />
                <TextField
                  className={classes.textField}
                  name="last_name"
                  autoComplete="family-name"
                  required
                  error={handleErrorOutline("last_name")}
                  onBlur={e => handleBlur(e)}
                  onFocus={e => handleFocus(e)}
                  style={{ width: "48%", marginTop: 6, marginBottom: 6 }}
                  onChange={handleDonorChange}
                  label="Last Name"
                  type="search"
                  variant="outlined"
                  value={donor.last_name}
                />
              </div>
              <TextField
                className={classes.textField}
                name="phone"
                autoComplete="tel"
                required
                error={handleErrorOutline("phone")}
                onBlur={e => handleBlur(e)}
                onFocus={e => handleFocus(e)}
                style={{ width: "100%", marginTop: 6, marginBottom: 6 }}
                onChange={handleDonorChange}
                label="Phone Number"
                type="search"
                variant="outlined"
                value={donor.phone}
              />
              <TextField
                className={classes.textField}
                name="email"
                autoComplete="email"
                required
                error={handleErrorOutline("email")}
                helperText={
                  donor.email &&
                  !validateEmail(donor.email) &&
                  "Please enter a valid email address"
                }
                onBlur={e => handleBlur(e)}
                onFocus={e => handleFocus(e)}
                style={{ width: "100%", marginTop: 6, marginBottom: 6 }}
                onChange={handleDonorChange}
                label="Email Address"
                type="search"
                variant="outlined"
                value={donor.email}
              />
              <h2 className={classes.headers}>Billing Address</h2>
              <TextField
                className={classes.textField}
                name="address_line_1"
                autoComplete="address-line1"
                required
                error={handleErrorOutline("address_line_1")}
                onBlur={e => handleBlur(e)}
                onFocus={e => handleFocus(e)}
                style={{ width: "100%", marginTop: 6, marginBottom: 6 }}
                onChange={handleDonorChange}
                label="Billing Address Line 1"
                type="search"
                variant="outlined"
                value={donor.address_line_1}
              />
              <TextField
                className={classes.textField}
                name="address_line_2"
                autoComplete="address-line2"
                style={{ width: "100%", marginTop: 6, marginBottom: 6 }}
                onChange={handleDonorChange}
                label="Billing Address Line 2"
                type="search"
                variant="outlined"
                value={donor.address_line_2}
              />
              <div className={classes.twoFields}>
                <TextField
                  className={classes.textField}
                  name="city"
                  autoComplete="address-level2"
                  required
                  error={handleErrorOutline("city")}
                  onBlur={e => handleBlur(e)}
                  onFocus={e => handleFocus(e)}
                  style={{
                    width: !isMobile ? "50%" : "66%",
                    marginTop: 6,
                    marginBottom: 6,
                    marginRight: !isMobile ? 20 : 0,
                  }}
                  onChange={handleDonorChange}
                  label="City"
                  type="search"
                  variant="outlined"
                  value={donor.city}
                />
                {/* causing console error when selecting state */}
                <SelectStateUS
                  className={classes.textField}
                  labelClassName={classes.inputLabel}
                  name="state"
                  autoComplete="address-level1"
                  required
                  error={handleErrorOutline("state")}
                  onBlur={e => handleBlur(e)}
                  onFocus={e => handleFocus(e)}
                  style={{
                    width: !isMobile ? "50%" : "30%",
                    marginTop: 6,
                    marginBottom: 6,
                  }}
                  onChange={handleDonorChange}
                  label="State"
                  variant="outlined"
                  value={donor.state}
                  disabled={donor.country !== "US"}
                />
              </div>
              <div className={classes.twoFields}>
                <TextField
                  className={classes.textField}
                  name="zip"
                  autoComplete="postal-code"
                  required
                  error={handleErrorOutline("zip")}
                  onBlur={e => handleBlur(e)}
                  onFocus={e => handleFocus(e)}
                  style={{
                    width: !isMobile ? "23%" : "48%",
                    marginTop: 6,
                    marginBottom: 6,
                    marginRight: !isMobile ? 20 : 0,
                  }}
                  onChange={handleDonorChange}
                  label="Zip Code"
                  type="search"
                  variant="outlined"
                  value={donor.zip}
                />
                <SelectCountry
                  className={classes.textField}
                  labelClassName={classes.inputLabel}
                  name="country"
                  autoComplete="country"
                  required
                  error={handleErrorOutline("country")}
                  onBlur={e => handleBlur(e)}
                  onFocus={e => handleFocus(e)}
                  style={{
                    width: !isMobile ? "100%" : "48%",
                    marginTop: 6,
                    marginBottom: 6,
                  }}
                  onChange={handleDonorChange}
                  label="Country"
                  variant="outlined"
                  value={donor.country}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              width: "50%",
              padding: 25,
              paddingTop: 0, // to align with the border right of previous div starting under these first titles
              display: "inline-block",
            }}
          >
            <h2 className={classes.headers}>Sponsorship Details</h2>
            <h3 className={classes.headers}>Dedication Type</h3>
            <div className={classes.dedicationTypesDiv}>
              {dedicationTypes.map((dt, i) => (
                <SelectionCard
                  key={i}
                  fontWeight={"bold"}
                  details={dt}
                  isActive={activeDedicationTypeCard.label === dt.label}
                  onClick={() => {
                    handleDedicationTypeChange(dt);
                  }}
                />
              ))}
            </div>
            {customDedicationType && (
              <TextField
                className={classes.textField}
                name="dedication_text"
                // error={handleErrorOutline("phone")}
                onBlur={e => handleBlur(e)}
                onFocus={e => handleFocus(e)}
                style={{
                  width: "100%",
                  marginTop: 6,
                  marginBottom: 6,
                }}
                onChange={e => {
                  setFullText(e.target.value);
                  dispatch(
                    DedicationActions.setDonor({
                      ...donor,
                      dedication_text: e.target.value,
                    }),
                  );
                }}
                label="Dedication Text"
                type="search"
                variant="outlined"
                value={fullText}
                autoFocus
              />
            )}
            {!customDedicationType && (
              <div>
                <h3 className={classes.headers}>Dedication Names</h3>
                <TextField
                  className={classes.textField}
                  // name="dedication_text"
                  // error={handleErrorOutline("phone")}
                  onBlur={e => handleBlur(e)}
                  onFocus={e => handleFocus(e)}
                  style={{
                    width: "100%",
                    marginTop: 6,
                    marginBottom: 6,
                  }}
                  onChange={e => setSponsoredByText(e.target.value)}
                  label="Sponsored by"
                  type="search"
                  variant="outlined"
                  value={sponsoredByText}
                />
                <TextField
                  className={classes.textField}
                  onBlur={e => handleBlur(e)}
                  onFocus={e => handleFocus(e)}
                  style={{
                    width: "100%",
                    marginTop: 6,
                    marginBottom: 6,
                  }}
                  onChange={e => setSponsoredForText(e.target.value)}
                  label="Sponsored for"
                  type="search"
                  variant="outlined"
                  value={sponsoredForText}
                />
              </div>
            )}
            <h2 className={classes.headers}>Sponsorship Preview</h2>
            <div
              style={{
                boxSizing: "border-box",
                border: "1px solid rgba(151,151,151,0.69)",
                boxShadow: "inset 0 1px 3px 0 rgba(0,0,0,0.17)",
                paddingTop: 8,
                paddingRight: 8,
                paddingLeft: 8,
                maxWidth: 600,
              }}
            >
              <div>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid lightblue",
                    padding: "4px 8px",
                    borderRadius: "6px 6px 0 0",
                    textAlign: "center",
                  }}
                >
                  <span>{fullText}</span>
                  {!customDedicationType && (
                    <Edit
                      style={{
                        color: "gray",
                        cursor: "pointer",
                        float: "right",
                        height: 18,
                      }}
                      onClick={() =>
                        handleDedicationTypeChange(
                          dedicationTypes.filter(d => d.name === "more")[0],
                        )
                      }
                    />
                  )}
                </div>
                <img
                  style={{ maxWidth: "100%" }}
                  src={`https://i.vimeocdn.com/video/${lecture.thumbnail}`}
                  alt="preview"
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div style={{ textAlign: "center" }}>
          <p>
            If you have any questions or need assistance with this form please
            email <a href="mailto:eli@torahanytime.com">eli@torahanytime.com</a>
          </p>
          <span
            style={{
              color: "rgba(0,0,0,0.8)",
              fontFamily: "Lato",
              fontSize: 24,
              fontWeight: "bold",
            }}
          >
            Total&emsp;
          </span>
          <span
            style={{
              fontFamily: "Lato",
              fontSize: 24,
              fontWeight: "bold",
            }}
          >
            $
            <NumberFormat
              value={72}
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </span>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          style={{
            backgroundColor: "#E07020",
            color: "#FFFFFF",
            width: 280,
            height: 50,
            marginBottom: 35,
          }}
          onClick={handleDonateClick}
        >
          <span>Enter Card Info</span>
        </Button>

        <StripeWrapper />
      </div>
    </div>
  );
}

export const ShiurPage = React.memo(_ShiurPage);
