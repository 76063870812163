import { DosePage } from "./DosePage";
import { ShiurPage } from "./ShiurPage";
import { ThankyouPage } from "./ThankyouPage";
import { ShiurThankyouPage } from "./ShiurThankyouPage";
import { CorporateSponsorshipPage } from "./CorporateSponsorshipPage";
import { NotFoundPage } from "./NotFoundPage";
import { CheckoutPage } from "./CorporateSponsorshipPage/CheckoutPage";

export const MainPages = {
  home: {
    anon: true,
    path: "/",
    title: "Home",
    type: "PAGE_HOME",
    view: DosePage,
  },
  dose: {
    anon: true,
    path: "/dose-sponsor",
    title: "Dose",
    type: "PAGE_DOSE",
    view: DosePage,
  },
  thankyou: {
    anon: true,
    path: "/dose-sponsor/thankyou/:paymentType",
    title: "Thankyou",
    type: "PAGE_THANKYOU",
    view: ThankyouPage,
  },
  corporatethankyou: {
    anon: true,
    path: "/corp-sponsor/thankyou",
    title: "Thankyou",
    type: "CORPORATE_PAGE_THANKYOU",
    view: ThankyouPage,
  },
  shiur: {
    anon: true,
    path: "/dose-sponsor/shiur-sponsor/lecture/:lectureId",
    title: "Lecture",
    type: "PAGE_SHIUR",
    view: ShiurPage,
    layout: "shiur",
  },
  shiurThankyou: {
    anon: true,
    path: "/dose-sponsor/shiur-sponsor/thankyou",
    title: "Thankyou",
    type: "PAGE_SHIUR_THANKYOU",
    view: ShiurThankyouPage,
    layout: "shiur",
  },
  corporateCheckout: {
    anon: true,
    path: "/corp-sponsor/checkout",
    title: "Corporate Sponsorship Checkout",
    type: "PAGE_DOSE_CORPORATE_SPONSORHIP_CHECKOUT",
    view: CheckoutPage,
  },
  corporate: {
    anon: true,
    path: "/corp-sponsor",
    title: "Corporate Sponsorship",
    type: "PAGE_DOSE_CORPORATE_SPONSORHIP",
    view: CorporateSponsorshipPage,
  },
  notFound: {
    anon: true,
    layout: null,
    path: "*",
    title: "Page Not Found",
    type: "PAGE_NOT_FOUND",
    view: NotFoundPage,
  },
};
export default MainPages;

export const MainArea = {
  pages: MainPages,
};
