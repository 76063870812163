import React from "react";
import { MenuItem, TextField } from "@material-ui/core";
// Local
import { states } from "../../assets/data/us_states";

const menuItems = states.map(state => (
  <MenuItem key={state.abbr} value={state.abbr}>
    {state.abbr}
    {state.abbr && state.name && " · "}
    {state.name}
  </MenuItem>
));

/**
 * @typedef {import("@material-ui/core").SelectProps} SelectProps
 *
 * @typedef {object} SelectStateUSProps
 * @property {string} [label]
 *
 * @param {SelectProps & SelectStateUSProps} props
 */
function _SelectStateUS({
  displayEmpty,
  children,
  className,
  labelClassName,
  label = "State",
  name = "state",
  onChange,
  value,
  style,
  error,
  autoComplete,
  ...props
}) {
  return (
    <TextField
      InputLabelProps={{
        shrink: true,
      }}
      label="State"
      name={name}
      onChange={onChange}
      SelectProps={{ displayEmpty: true }}
      select
      error={error}
      value={value}
      variant="outlined"
      style={style}
      autoComplete={autoComplete}
    >
      {menuItems}
    </TextField>
  );
}

export const SelectStateUS = React.memo(_SelectStateUS);
