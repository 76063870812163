import React, { useState } from "react";
import { Button } from "@material-ui/core";
// Local
import { useSelector, DedicationsSelectors } from "../../state";
import { useMobile } from "../../themes";
import doseImage from "../../assets/img/donat-illustration-ty.png";
import { useParams } from "react-router-dom";
import { updateDonorApiCall, cleanLabel } from "../../lib";
import { Redirect } from "react-router-dom";
import ReactGA from "react-ga";
const { REACT_APP_IMAGE_PATH } = process.env;

function _ThankyouPage() {
  // const dispatch = useDispatch();
  const dedications = useSelector(DedicationsSelectors.allFilledDedications);
  const donor = useSelector(DedicationsSelectors.donor);
  const currency = useSelector(DedicationsSelectors.currency);
  const isMobile = useMobile();
  const { paymentType } = useParams();

  const [donated, setDonated] = useState(
    paymentType !== "phone" && paymentType !== "zelle",
  );
  const setDonateComplete = async () => {
    const res = await updateDonorApiCall(donor);
    if (res.status !== 200) {
      alert(
        "We're sorry an error occurred. Please call (718) 291-2818 to ensure that we have received your dedication and payment.",
      );
      return;
    }
    setDonated(true);
  };

  const handleShareAnalytics = sharedVia => {
    ReactGA.event({
      category: "Completed",
      action: "share",
      label: `${sharedVia} - ${paymentType} donation`,
    });
  };

  // using object for now. should switch to class
  const iconStyle = {
    margin: 8,
    cursor: "pointer",
    height: 44,
    width: 44,
  };
  const firstDedication = dedications[0];

  // check if page was reloaded
  if (!donor.email) {
    return <Redirect to={"/"} />;
  }
  let body = "";
  if (!donor.is_corporate_sponsor) {
    const firstDedicationDate = new Date(firstDedication.day);
    body =
      dedications.length <= 1
        ? `I just made this Daily Dose dedication.%0D%0A%0D%0A${cleanLabel(
            firstDedication.dedicationType.label,
          )} for ${
            firstDedication.name
          } will be displayed on ${firstDedicationDate.getMonth() +
            1}/${firstDedicationDate.getDate()}/${firstDedicationDate.getFullYear()}.%0D%0A%0D%0APlease lookout for it!`
        : `I just made these Daily Dose dedications.%0D%0A%0D%0A${dedications // it's important that this mapping begin on this line, otherwise extra spaces were being inserted.
            .map(d => {
              const dDate = new Date(d.day);
              return `${cleanLabel(d.dedicationType.label)} for ${
                d.name
              } will be displayed on ${dDate.getMonth() +
                1}/${dDate.getDate()}/${dDate.getFullYear()}.`;
            })
            .join("%0D%0A%0D%0A")}%0D%0A%0D%0APlease lookout for them!`;
    body += `%0D%0A%0D%0AIf you're not receiving the TorahAnytime Daily Dose, please click to join https://wa.me/19293554268?text=Add-Me`;
  }
  return (
    <div
      style={{
        textAlign: "center",
        minHeight: `calc(100vh - ${isMobile ? 113 : 144}px)`,
      }}
    >
      <img src={doseImage} alt="" style={{ marginTop: isMobile ? 30 : 100 }} />
      <br />
      {!donated && (paymentType === "phone" || paymentType === "zelle") && (
        <div>
          {paymentType === "phone" ? (
            <div>
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Please call
                <br />
                <a style={{ fontSize: 20 }} href="tel:+17182912818">
                  (718) 291-2818
                </a>
                <br /> to make your total donation amount below.
              </span>
            </div>
          ) : (
            <div>
              <h2>
                Please QuickPay the total donation amount below to
                TorahAnytime@gmail.com.
              </h2>
              <h2>Please enter Daily Dose Dedication in the Notes.</h2>
            </div>
          )}
          <h1>
            {currency.symbol}
            {donor.total_amount}
          </h1>
          <span>
            Please click "Done" after you have completed your donation.
          </span>
        </div>
      )}
      {donated && (
        <h1
          style={{
            color: "#1D78B9",
            fontFamily: "Lato",
            fontSize: isMobile ? 46 : 56,
          }}
        >
          Thank you for your donation!
        </h1>
      )}
      {!donated && (
        <Button
          style={{
            backgroundColor: "#E07020",
            color: "#FFFFFF",
            height: 52,
            paddingRight: 50,
            paddingLeft: 50,
            marginTop: 10,
          }}
          onClick={() => setDonateComplete()}
        >
          <span style={{ fontSize: 18 }}>Done</span>
        </Button>
      )}
      {donated && !donor.is_corporate_sponsor && (
        <div>
          <div
            style={{
              fontSize: 35,
              marginBottom: 15,
            }}
          >
            For a chance to receive a free future dedication{" "}
            <a
              target="_blank"
              style={{
                color: "rgb(29, 120, 185)",
              }}
              href="https://mytat.me/thankyoupage"
            >
              click here
            </a>
          </div>
          <div
            style={{
              textAlign: "center",
              fontFamily: "lato",
              color: "#555555",
            }}
          >
            <span>
              Let your friends & family know to look out for your dedication
              {dedications.length > 1 && "s"}.
            </span>
          </div>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?quote=${body}&u=torahanytime.com`}
            onClick={() => handleShareAnalytics("facebook")}
          >
            <img
              style={iconStyle}
              alt=""
              src={`${REACT_APP_IMAGE_PATH}/share_facebook.png`}
            />
          </a>
          <a
            href={`mailto:?body=${body}`}
            onClick={() => handleShareAnalytics("email")}
          >
            <img
              style={iconStyle}
              alt=""
              src={`${REACT_APP_IMAGE_PATH}/share_email.png`}
            />
          </a>
          <a
            href={`https://twitter.com/intent/tweet?text=${body}`}
            onClick={() => handleShareAnalytics("twitter")}
          >
            <img
              style={iconStyle}
              alt=""
              src={`${REACT_APP_IMAGE_PATH}/share_twitter.png`}
            />
          </a>
          <a
            href={`https://wa.me?text=${body}`}
            data-action="share/whatsapp/share"
            onClick={() => handleShareAnalytics("whatsapp")}
          >
            <img
              style={iconStyle}
              alt=""
              src={`${REACT_APP_IMAGE_PATH}/share_whatsapp.png`}
            />
          </a>
        </div>
      )}
    </div>
  );
}

export const ThankyouPage = React.memo(_ThankyouPage);
