import React from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@material-ui/core";
import {
  DedicationsSelectors,
  DedicationActions,
  useSelector,
  useDispatch,
} from "../../state";
import { shallowEqual } from "react-redux";
import { DropZone } from "../../components";
import { makeStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

export const StatusSlide = function({ slideNumber, ...props }) {
  const useStyles = makeStyles(theme => ({
    slideRadioOptions: {
      justifyContent: "space-between",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      marginBottom: 15,
      "& .MuiFormControlLabel-root": {
        width: "32%",
        margin: 0,
        padding: 0,
      },
    },
    slideTxtHelper: {
      display: "flex",
      justifyContent: "space-between",
    },
  }));
  const classes = useStyles();
  const donor = useSelector(DedicationsSelectors.donor, shallowEqual);
  const dispatch = useDispatch();
  const getTextCharCount = (str = donor[`slide_${slideNumber}_text`]) =>
    (str?.length || 0) +
    ((str || "").match(new RegExp("\\n", "g")) || []).length * 49;

  return (
    <div
      style={{
        textAlign: "left",
        display: "flex",
        fontSize: 10,
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <RadioGroup
        aria-setsize={6}
        aria-label="position"
        name="position"
        className={classes.slideRadioOptions}
        value={donor[`slide_${slideNumber}_type`]}
        onChange={e => {
          dispatch(
            DedicationActions.setDonor({
              ...donor,
              [`slide_${slideNumber}_type`]: e.target.value,
              [`slide_${slideNumber}_file`]: undefined,
              [`slide_${slideNumber}_text`]: "",
            }),
          );
        }}
      >
        <FormControlLabel
          value="image"
          control={<Radio size="small" color="primary" />}
          label="Image"
        />
        <FormControlLabel
          value="video"
          control={<Radio size="small" color="primary" />}
          label="Video"
        />
        <FormControlLabel
          value="text"
          control={<Radio size="small" color="primary" />}
          label="Text"
        />
      </RadioGroup>
      {donor[`slide_${slideNumber}_file`] && (
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            {donor[`slide_${slideNumber}_type`] === "image" && (
              <img
                src={donor[`slide_${slideNumber}_file`].mysrc}
                alt=""
                style={{ width: "100%" }}
              />
            )}
            {donor[`slide_${slideNumber}_type`] === "video" && (
              <video
                style={{ width: "100%" }}
                controls
                key={donor[`slide_${slideNumber}_file`].size}
              >
                <source
                  src={donor[`slide_${slideNumber}_file`].mysrc}
                  // type={fileType}
                />
                {/* Your browser does not support HTML5 video. */}
              </video>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  fontSize: 14,
                  overflow: "hidden",
                  // whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  margin: "10px 0",
                  maxWidth: "calc(100% - 34px)",
                }}
              >
                {donor[`slide_${slideNumber}_file`].name}
              </span>

              <HighlightOffIcon
                color="error"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  dispatch(
                    DedicationActions.setDonor({
                      ...donor,
                      [`slide_${slideNumber}_file`]: undefined,
                    }),
                  )
                }
              />
            </div>
          </div>
        </div>
      )}
      {
        {
          video: (
            <DropZone type="Video" slideNumber={slideNumber} txt="1M Max" />
          ),
          image: (
            <DropZone
              type="Image"
              slideNumber={slideNumber}
              txt="30Sec/10M Max"
            />
          ),
          text: (
            <TextField
              value={donor[`slide_${slideNumber}_text`]}
              onChange={e => {
                if (getTextCharCount(e.target.value) < 701) {
                  dispatch(
                    DedicationActions.setDonor({
                      ...donor,
                      [`slide_${slideNumber}_text`]: e.target.value,
                    }),
                  );
                }
              }}
              inputProps={{
                maxLength:
                  700 -
                  (
                    (donor[`slide_${slideNumber}_text`] || "").match(
                      new RegExp("\\n", "g"),
                    ) || []
                  ).length *
                    49,
              }}
              helperText={
                <span className={classes.slideTxtHelper}>
                  <span>{`${getTextCharCount()}/700`}</span>
                  <span>Note: Each new line counts as 50 characters</span>
                </span>
              }
              style={{ width: "100%" }}
              maxLength={700}
              multiline
              rows={12}
              variant="outlined"
            />
          ),
        }[donor[`slide_${slideNumber}_type`]]
      }
    </div>
  );
};
