import React from "react";
import { ToastContainer } from "react-toastify";

// Local
// import { InformationalCard } from "../components/cards/Informational";

function _ShiurLayout({ children }) {
  return (
    <>
      <div>{children}</div>
      <ToastContainer />
    </>
  );
}

export const ShiurLayout = React.memo(_ShiurLayout);
