import React, { useEffect } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import {
  UIActions,
  useDispatch,
  useSelector,
  DedicationsSelectors,
  DedicationActions,
} from "../../../state";

import { DailyDoseWhatsAppCorpSponsorshipPage } from "./DailyDoseWhatsAppSponsorship";
import { DailyDoseEmailCorporateSponsorship } from "./DailyDoseEmailSponsorshipt";

const TabPanel = props => {
  const { children, value, name } = props;

  return <div hidden={value !== name}>{children}</div>;
};

export const CorporateSponsorshipPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const donor = useSelector(DedicationsSelectors.donor);

  useEffect(() => {
    dispatch(UIActions.setHeaderText("corporate"));
    dispatch(
      DedicationActions.setDonor({ ...donor, is_corporate_sponsor: true }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (event, newValue) => {
    console.log("changing");
    // tabSetValue(newValue);
    const search = new URLSearchParams(history.location.search);

    search.set("tab", newValue);

    history.push({
      pathname: history.location.pathname,
      search: `?${search.toString()}`,
    });
  };

  const tabValue =
    new URLSearchParams(history.location.search).get("tab") || "whatsapp";

  return (
    <div style={{ minHeight: "100vh" }}>
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="WhatsApp" value="whatsapp" />
        <Tab label="Email" value="email" />
      </Tabs>

      <TabPanel value={tabValue} name="whatsapp">
        {/* <div>hi</div> */}
        <DailyDoseWhatsAppCorpSponsorshipPage />
      </TabPanel>

      <TabPanel value={tabValue} name="email">
        <DailyDoseEmailCorporateSponsorship />
      </TabPanel>
    </div>
  );
};
