import { Dialog, DialogTitle, IconButton } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { useMobile } from "../../themes";
import corporate_sponsor_sample from "../../assets/img/corporate_sponsor_sample.jpg";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

export const SampleDoseMessage = function({ setDialogOpen, dialogOpen }) {
  const [dialogRefAvail, setDialogRefAvail] = useState(false);
  const [imgHeight, setImgHeight] = useState("auto");
  const isMobile = useMobile();
  const dialogRef = useRef();
  const useStyles = makeStyles(theme => ({
    previewLink: {
      textAlign: "right",
    },
    closeIcon: {
      color: "red",
    },
    closeButton: {
      position: "absolute",
      right: 15,
      top: 15,
      width: isMobile ? 20 : 30,
      height: isMobile ? 20 : 30,
    },
    dialogTitle: {
      "& .MuiTypography-root": {
        fontSize: isMobile ? 14 : 20,
        textAlign: "center",
      },
    },
  }));
  const classes = useStyles();
  useEffect(() => {
    console.log("dialogRef", dialogRef.current);
    if (!dialogOpen) return;
    const timer = setInterval(() => {
      if (dialogRef.current) {
        setDialogRefAvail(true);
        clearInterval(timer);
      }
    }, 100);
    if (!dialogRefAvail) return;
    dialogRef.current && setImgHeight(dialogRef.current.clientHeight - 130);
    return () => clearInterval(timer);
  }, [dialogOpen, dialogRefAvail]);

  return (
    <Dialog
      open={dialogOpen}
      ref={dialogRef}
      onBackdropClick={() => setDialogOpen(false)}
    >
      <DialogTitle className={classes.dialogTitle}>
        Sample Corporate Message
        <IconButton
          className={classes.closeButton}
          onClick={_ => setDialogOpen(false)}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>
      <img
        style={{ height: imgHeight }}
        src={corporate_sponsor_sample}
        alt="corporate_sponsor_sample"
      />
    </Dialog>
  );
};
