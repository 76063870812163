import React, { useState } from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import {
  DedicationsSelectors,
  DedicationActions,
  useSelector,
  useDispatch,
} from "../../state";

import { SelectionCard, DedicationSummary } from "../../components";
import AddIcon from "@material-ui/icons/Add";
import { DatePicker } from "@material-ui/pickers";

// import "react-datepicker/dist/react-datepicker.css";

import { canContinueToDonor } from "../../lib";
import { utcToZonedTime } from "date-fns-tz";
import { useEffect } from "react";
require("date-time-format-timezone");

const verticalFlex = { display: "flex", alignItems: "center" };
const mrg = margin => ({ margin });
const newDedicationName = () => ({
  name: "",
  day: "",
  isdose: true,
  dedicationType: {},
});

function _DedicationCard({
  dedication,
  index,
  dedicationTypes = [{}],
  moreDedicationTypes = [{}],
  sponsorshipTypes,
  getFirstSecond,
  showErrorFields,
  ...props
}) {
  const dispatch = useDispatch();
  const allDedicationsExceptActive = useSelector(
    DedicationsSelectors.allDedicationsExceptActive,
  );
  const allDedications = useSelector(DedicationsSelectors.allDedications);
  const lastDedication = useSelector(DedicationsSelectors.getLastItem);

  const useStyles = makeStyles(theme => ({
    addAdditionalName: {
      backgroundColor: "#FFFFFF",
      color: "#1D78B9",
      display: "flex",
      marginTop: 8,
      alignItems: "center",
      fontSize: 18,
      fontWeight: 700,
      width: "65%",
      "&:hover": {
        opacity: 0.7,
      },
      cursor: "pointer",
    },
    label: {
      backgroundColor: "white",
    },
    dedicationTypesDiv: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    textField: {
      [`& fieldset`]: {
        borderRadius: 0,
      },
    },
  }));
  const classes = useStyles();

  const [activeDedicationTypeCard, setActiveDedicationTypeCard] = useState({});
  const [currentIndex, setCurrentIndex] = useState(index);
  if (index !== currentIndex) {
    setActiveDedicationTypeCard({});
    setCurrentIndex(index);
  }
  const [moreDedicationTypeSelected, setMoreDedicationTypeSelected] = useState(
    "",
  );

  //#region

  // The Daily Dose app passes in a query parameter for a specific Dedication
  // type to be preselected.
  // We take check for it in the query parameter, and parse it.
  const queryParams = new URLSearchParams(window.location.search); // refactored in case more query params ever need to be passed-in
  const dedicationTypeQueryParamString = queryParams.get("dedicationType");
  const dedicationTypeQueryParm = dedicationTypeQueryParamString
    ? parseInt(dedicationTypeQueryParamString)
    : undefined;

  // When the component is created, the dedication type list is empty.
  // We need to select the preselected dedication type once this list
  // is populated.
  useEffect(() => {
    setActiveDedicationTypeCard(
      dedicationTypes.find(d => d.id === dedicationTypeQueryParm) || {},
    );
  }, [dedicationTypes, dedicationTypeQueryParm]);

  //#endregion

  // If should show errors. only sets to true after trying to add another dedication name and the current one does not have enough info. Then it shows error outlines on what is missing.
  const [showValidationErrors, setShowValidationErrors] = useState(null);
  const [textFieldsTouched, setTextFieldsTouched] = useState([]);
  if (showErrorFields && !showValidationErrors) {
    setShowValidationErrors(true);
  }
  // Handle functions:
  const handleDedicationTypeChange = dedicationType => {
    const editedDedication = { ...lastDedication };
    editedDedication.dedicationType = dedicationType;

    setActiveDedicationTypeCard(dedicationType);
    if (!dedicationType.showMore) {
      setTextFieldsTouched([...textFieldsTouched, "type"]);
      editedDedication.dedication_type_id = dedicationType.id;
      setMoreDedicationTypeSelected("");
    } else {
      editedDedication.dedication_type_id = undefined;
    }
    dispatch(DedicationActions.updateDedication(editedDedication));
  };
  const handleMoreDedicationTypeChange = e => {
    setMoreDedicationTypeSelected(e.target.value);
    dedication.dedication_type_id = e.target.value;
    const editedDedication = { ...lastDedication };
    editedDedication.dedication_type_id = e.target.value;
    dispatch(DedicationActions.updateDedication(editedDedication));
  };
  const handleChange = e => {
    const { name, value } = e.target;
    const editedDedication = { ...lastDedication };
    editedDedication[name] = value;
    dispatch(DedicationActions.updateDedication(editedDedication));
  };
  const handleDateChange = e => {
    const editedDedication = { ...lastDedication };
    // Getting the users month based on user's locale, as the datepicker is using the locale to render. The values will be stored in db as UTC day, month, year, as that is the dedication date that the user is dedicating
    editedDedication.day = `${e.getMonth() +
      1}/${e.getDate()}/${e.getFullYear()}`;
    dispatch(DedicationActions.updateDedication(editedDedication));
  };

  const handleClear = () => {
    dispatch(DedicationActions.updateDedication(newDedicationName()));
    setActiveDedicationTypeCard({});
    // if it's at least 2, then not required if no fields are filled out which they are not, as they are being cleared
    if (allDedications.length < 2) {
      setShowValidationErrors(true);
    } else {
      setShowValidationErrors(false);
    }
  };

  const validate = () =>
    dedication.dedication_type_id && dedication.name && dedication.day;

  const handleBlur = e => {
    setTextFieldsTouched([...textFieldsTouched, e.target.name]);
  };

  const handleFocus = e => {
    setTextFieldsTouched(textFieldsTouched.filter(f => f !== e.target.name));
  };

  return (
    <div
      style={{
        maxWidth: "650px",
        width: "100%",
        margin: "auto",
        // border: "1px solid #979797",
        padding: 14,
      }}
    >
      {/* <h2>Sponsorship Type</h2>
      <div>
        {Object.keys(sponsorshipTypes).map(key => (
          <SelectionCard
            key={key}
            details={sponsorshipTypes[key]}
            isActive={dedication[`is${key}`]}
            onClick={() => {
              dedication[`is${key}`] = !dedication[`is${key}`];
              setDedication();
            }}
          />
        ))}
      </div> */}
      <div
        style={{ textAlign: "center", fontFamily: "lato", color: "#555555" }}
      >
        <span>
          Get your dedications seen by many thousands of people.
          <br />
          It's only $52 per dedication and you can use Ma'aser.
        </span>
      </div>
      <br />
      <h2
        style={{
          display: "block",
          margin: "auto",
          textAlign: "center",
          outline:
            !lastDedication.dedication_type_id &&
              (showValidationErrors ||
                textFieldsTouched.filter(f => f === "type").length > 0) &&
              !canContinueToDonor(allDedications)
              ? "1px solid red"
              : null,
        }}
      >
        Choose a Dedication Type
      </h2>
      <div className={classes.dedicationTypesDiv}>
        {dedicationTypes.map((dt, i) => (
          <SelectionCard
            key={i}
            fontWeight={"bold"}
            details={dt}
            isActive={activeDedicationTypeCard.id === dt.id}
            onClick={() => {
              handleDedicationTypeChange(dt);
            }}
          />
        ))}
      </div>
      {activeDedicationTypeCard.showMore && (
        <FormControl
          style={{ width: "100%", marginTop: 12, marginBottom: 16 }}
          variant="outlined"
        >
          <InputLabel className={classes.label} id="moreTypes">
            Select from additional dedication types
          </InputLabel>
          <Select
            className={classes.textField}
            labelId="moreTypes"
            onChange={handleMoreDedicationTypeChange}
            value={moreDedicationTypeSelected}
            defaultValue={0}
          >
            {moreDedicationTypes.map(dt => (
              <MenuItem key={dt.id} value={dt.id}>
                {dt.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {allDedicationsExceptActive.length > 0 && (
        <div
          style={{
            marginTop: 20,
          }}
        >
          {allDedicationsExceptActive.map((dc, i) => (
            <DedicationSummary key={i} dedication={dc} index={i} />
          ))}
        </div>
      )}
      <div style={{ ...verticalFlex, marginTop: 14 }}>
        <div style={{ ...verticalFlex, width: "100%" }}>
          <Tooltip
            enterDelay={1}
            enterTouchDelay={1}
            title={
              !lastDedication.dedication_type_id
                ? "Please select a dedication type above"
                : ""
            }
            leaveTouchDelay={3000}
          >
            <TextField
              className={classes.textField}
              style={{ width: "75%" }}
              name="name"
              disabled={!lastDedication.dedication_type_id}
              required={!canContinueToDonor(allDedications)}
              onBlur={e => handleBlur(e)}
              onFocus={e => handleFocus(e)}
              error={
                !lastDedication.name &&
                !canContinueToDonor(allDedications) &&
                (showValidationErrors ||
                  textFieldsTouched.filter(f => f === "name").length > 0)
              }
              value={lastDedication.name}
              onChange={e => handleChange(e)}
              label={`${getFirstSecond(index + 1)} dedication name`}
              type="search"
              variant="outlined"
              inputProps={{
                style:
                  !lastDedication.name || lastDedication.name.length < 22
                    ? {
                      height: 50,
                      padding: "0 8px",
                      fontSize: 16,
                    }
                    : lastDedication.name.length < 26
                      ? {
                        height: 50,
                        padding: "0 8px",
                        fontSize: 14,
                      }
                      : {
                        height: 50,
                        padding: "0 8px",
                        paddingRight: 4,
                        paddingLeft: 4,
                        fontSize: 12,
                      },
              }}
            />
          </Tooltip>
          <Tooltip
            enterDelay={1}
            enterTouchDelay={1}
            title={
              !lastDedication.dedication_type_id
                ? "Please select a dedication type above"
                : ""
            }
            leaveTouchDelay={3000}
          >
            <div style={{ ...mrg(3), width: "25%" }}>
              <DatePicker
                format={"MM/dd"}
                name="day"
                disabled={!lastDedication.dedication_type_id}
                required={!canContinueToDonor(allDedications)}
                onBlur={e => handleBlur(e)}
                onFocus={e => handleFocus(e)}
                error={
                  !lastDedication.day &&
                  !canContinueToDonor(allDedications) &&
                  (showValidationErrors ||
                    textFieldsTouched.filter(f => f === "day").length > 0)
                }
                autoOk={true}
                value={lastDedication.day || null}
                onChange={e => handleDateChange(e)}
                label="MM/DD"
                InputLabelProps={
                  !lastDedication.day
                    ? {
                      style: { transform: "translate(6px, 20px) scale(1)" },
                    }
                    : {}
                }
                inputVariant="outlined"
                variant="inline"
                className={classes.textField}
                inputProps={{
                  style: {
                    height: 50,
                    padding: "0 8px",
                    textAlign: "center",
                  },
                }}
                // Disabling today unless thurs before 12 PM EST.
                shouldDisableDate={day => {
                  const todEstDate = utcToZonedTime(
                    new Date(),
                    "America/New_York",
                  );
                  // yesterday's date in order to disable before today as today is conditional if Thursday before 12 PM EST
                  let yestEstDate = utcToZonedTime(
                    new Date(),
                    "America/New_York",
                  );
                  yestEstDate.setDate(yestEstDate.getDate() - 1);
                  const isThursday = todEstDate.getDay() === 4;
                  return (
                    day < yestEstDate || // this line is disabling a/t before today as day is 00:00 so would be less than whatever time it is
                    (day.toDateString() === todEstDate.toDateString() &&
                      (!isThursday || todEstDate.getHours() >= 12))
                  );
                }}
              />
              {/* <DatePicker
              selected={dedicationName.day}
              name="day"
              required
              dateFormat="MM/dd"
              minDate={new Date()}
              style={{ ...mrg(3), width: "25%", height: 76 }}
              value={dedicationName.day}
              onChange={e => handleDateChange(e, i)}
              placeholderText="DD/MM"
            /> */}
            </div>
          </Tooltip>
        </div>
        <HighlightOff
          style={{ cursor: "pointer", color: "lightgray" }}
          onClick={handleClear}
        />
      </div>
      <div
        className={classes.addAdditionalName}
        onClick={() => {
          if (validate()) {
            dispatch(DedicationActions.startNewDedication());
            setShowValidationErrors(false);
          } else {
            setShowValidationErrors(true);
          }
          setTextFieldsTouched([]);
          // dedicationCards.push(newDedicationCard());
          // setDedicationCards([...dedicationCards]);
        }}
      >
        <AddIcon />
        Add additional name
      </div>

      <div>
        {/* {dedicationCards.slice(0, -1).map((dc, i) => (
                <DedicationSummary
                  key={i}
                  dedication={dc}
                  index={i}
                  handleDelete={handleDeleteDedication}
                  getFirstSecond={firstSecond}
                />
              ))} */}
      </div>
    </div>
  );
}

export const DedicationCard = React.memo(_DedicationCard);
