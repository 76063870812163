import React from "react";
import { Button } from "@material-ui/core";

const paymentTypes = [
  {
    name: "stripe",
    displayName: "Credit / Debit",
  },
  {
    name: "paypal",
    displayName: "Paypal",
  },
];
export const PaymentTypes = function(props) {
  const { donor, setDonor } = props.globalItems;
  return (
    <div style={{ textAlign: "center" }}>
      <h2 style={{ display: "block", textAlign: "center" }}>Donation Method</h2>
      {paymentTypes.map(
        (pt, i) =>
          (pt.name !== "paypal" || !donor.cannotDoPaypal) && (
            <Button
              key={i}
              style={{
                height: 62,
                width: "50%",
                backgroundColor:
                  donor.payment_type === pt.name ? "#1D78B9" : "white",
                color: donor.payment_type === pt.name ? "white" : "#424848",
                fontFamily: "Lato",
                fontWeight: "bold",
                borderRadius: 0,
                borderShadow: "none",
                border: "1px #D9D9D9 solid",
              }}
              onClick={() => setDonor({ payment_type: pt.name })}
            >
              {pt.displayName}
            </Button>
          ),
      )}
    </div>
  );
};
