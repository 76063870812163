const type = {
  UPDATE_ACTIVE_DEDICATION: "UPDATE_ACTIVE_DEDICATION",
  START_NEW_DEDICATION: "START_NEW_DEDICATION",
  REMOVE_DEDICATION: "REMOVE_DEDICATION",
  SET_STRIPE_OPEN: "SET_STRIPE_OPEN",
  SET_DONATE_SUCCESS: "SET_DONATE_SUCCESS",
  SET_DONOR: "SET_DONOR",
  SET_CURRENCY: "SET_CURRENCY",
  SET_LECTURE_ALREADY_SPONSORED: "SET_LECTURE_ALREADY_SPONSORED",
  INITIATE_CORPORATE_SPONSOR_BEGIN: "INITIATE_CORPORATE_SPONSOR_BEGIN",
  INITIATE_CORPORATE_SPONSOR_SUCCESS: "INITIATE_CORPORATE_SPONSOR_SUCCESS",
  INITIATE_CORPORATE_SPONSOR_ERROR: "INITIATE_CORPORATE_SPONSOR_ERROR",
};

export const DedicationActions = {
  type,

  updateDedication(item) {
    return { type: type.UPDATE_ACTIVE_DEDICATION, item };
  },
  startNewDedication() {
    return { type: type.START_NEW_DEDICATION };
  },
  removeDedication(index) {
    return { type: type.REMOVE_DEDICATION, index };
  },
  //not sure if this is the right place for this
  setStripeOpen(open) {
    return { type: type.SET_STRIPE_OPEN, open };
  },
  setLectureAlreadySponsored(sponsored) {
    return { type: type.SET_LECTURE_ALREADY_SPONSORED, sponsored };
  },
  setDonateSuccess(success) {
    return { type: type.SET_DONATE_SUCCESS, success };
  },
  setDonor(donor) {
    return { type: type.SET_DONOR, donor };
  },
  setCurrency(currency) {
    return { type: type.SET_CURRENCY, currency };
  },
};
