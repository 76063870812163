import React from "react";

import doseImage from "../../assets/img/donat-illustration copy 2.png";
import goldImage from "../../assets/img/Sponser-illustration-gold Copy 2.png";

/**
 * @typedef {import("@material-ui/core").SelectProps} SelectProps
 *
 * @typedef {object} SelectStateUSProps
 * @property {string} [label]
 *
 * @param {SelectProps & SelectStateUSProps} props
 */

function _InformationalCard({
  displayEmpty,
  children,
  className,
  onChange,
  value,
  ...props
}) {
  const { cardType } = props;
  const imageUrl = cardType === "dose" ? doseImage : goldImage;
  const title = cardType === "dose" ? "Dose Sponsor" : "Status Sponsor";
  const summary =
    cardType === "dose"
      ? "Sponsor a Daily Dose"
      : "Sponsor the Daily Dose group for a day";
  const amount = cardType === "dose" ? "$52" : "$101";
  const description =
    cardType === "dose"
      ? "Donor's name and Dedication will display on the Daily Dose post"
      : "Donor's name and Dedication will display on the Daily Dose's group status";
  const style = {
    margin: 0,
    borderRadius: 4,
    backgroundColor: "#FFFFFF",
    boxShadow:
      "0 9px 46px 8px rgba(0,0,0,0.12), 0 24px 38px 3px rgba(0,0,0,0.14), 0 11px 15px -7px rgba(0,0,0,0.2)",
    display: "inline-block",
    textAlign: "center",
    padding: 20,
    width: "45%",
  };
  if (cardType === "status") {
    style.float = "right";
  }
  return (
    <div style={style}>
      <div>
        <img src={imageUrl} alt="" />
        <h2>{title}</h2>
        <h4>{summary}</h4>
        <h1>{amount}</h1>
        <span>{description}</span>
        <br></br>
        <br></br>
        {/* TODO: wire up the click of the link */}
        <a href="#">How will my dedication be displayed?</a>
      </div>
    </div>
  );
}

export const InformationalCard = React.memo(_InformationalCard);
