import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { ToastContainer } from "react-toastify";
import { Button, TextField, InputAdornment } from "@material-ui/core";
import { StripeWrapper } from "./common/Stripe";
import { makeStyles } from "@material-ui/core/styles";
import {
  donorIsValid,
  corporateInfoIsValid,
  corporateDonorIsValid,
  updateDonorApiCall,
} from "../lib/utils";
import { authPost, prepDonorForRequest } from "../lib";
import { useDispatch, DedicationActions } from "../state";
import axios from "axios";
import { toast } from "react-toastify";
import { LineItem } from "../components";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { PayPalButton } from "react-paypal-button-v2";

const { REACT_APP_API_URL, REACT_APP_PAYPAL_CLIENT_ID } = process.env;
export const DonationSection = function(props) {
  const {
    globalItems: {
      handleDonorChange,
      donor,
      getTotalAmount,
      setShowErrorFields,
      setDonor,
      getMessageAndStatusPackageAmount,
      getExtraMessageCount,
      getExtraStatusCount,
      packageAmount,
      messageAmount,
      statusAmount,
    } = {},
    goToDonorRef,
    goToSponsorshipRef,
    getDiscountAmount,
    getFullPriceAmount,
  } = props;
  const [showCouponSection, setShowCouponSection] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const dispatch = useDispatch();
  const handleDonateClick = async () => {
    if (!corporateInfoIsValid(donor)) {
      setShowErrorFields(true);
      goToSponsorshipRef();
      toast("Invalid sponsorship info", { type: "error" });
      return;
    }
    if (!donorIsValid(donor)) {
      setShowErrorFields(true);
      goToDonorRef();
      return;
    }
    const totalAmount = getTotalAmount();

    var formData = new FormData();
    formData.append("slide_1_file", donor.slide_1_file);
    formData.append("slide_2_file", donor.slide_2_file);
    formData.append("id", donor.id);

    await setDonor({
      total_amount: totalAmount,
      additional_amount: donor.additional_amount || 0,
    });

    const [response, files_response] = await Promise.all([
      axios
        .create({
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        })
        .post("/dose-dedication", {
          donor: {
            ...prepDonorForRequest(donor),
            payment_status: "pending",
            total_amount: totalAmount,
            additional_amount: donor.additional_amount || 0,
          },
        })
        .catch(err => {
          toast("An error occurred!", {
            type: "error",
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
          });
          throw err;
        }),

      axios
        .create({
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .post("/corporate-status-attachments", formData)
        .catch(err => {
          toast("An error occurred!", {
            type: "error",
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
          });
          throw err;
        }),
    ]);
    if (donor.payment_type === "stripe") {
      setDonor({
        id: response.data.donor_id,
        total_amount: totalAmount,
        additional_amount: donor.additional_amount || 0,
        clientSecret: response.data.clientSecret,
      });
      dispatch(DedicationActions.setStripeOpen(true));
    }
  };
  const checkCouponCode = async code => {
    if (!code) return;
    const discount = await axios
      .create({
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      })
      .get(`/dose-discount-code/${code}`)
      .catch(err => {
        toast("An error occurred!", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
        throw err;
      });
    if (!discount.data.id) {
      toast("Invalid code", {
        type: "error",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
      });
      setCouponCode("");
      return;
    }
    setDonor({ discount_code_id: discount.data.id, discount: discount.data });
    console.log("discount", discount);
  };
  const useStyles = makeStyles(theme => ({
    addAdditionalName: {
      backgroundColor: "#D8D8D8",
      color: "#1D78B9",
      padding: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: 18,
      fontWeight: 700,
      marginTop: 20,
      width: "65%",
      "&:hover": {
        opacity: 0.7,
      },
    },
    label: {
      backgroundColor: "white",
    },
    textField: {
      [`& fieldset`]: {
        borderRadius: 0,
      },
    },
    twoFields: {
      display: "flex",
      justifyContent: "space-between",
    },
    inputLabel: {
      paddingLeft: 18.5,
    },
    headers: {
      display: "block",
      textAlign: "center",
    },
  }));
  const classes = useStyles();
  // did as object for now as want to use multiple
  const summaryItem = {
    fontSize: { fontSize: 14 },
    nameFontFamily: { fontFamily: ".SF NS Display" },
    amountFontFamily: { fontFamily: "Lato" },
    amountColor: { color: "#1D78B9" },
    nameWidth: { width: "75%" },
    amountWidth: { width: "25%" },
    amountFontWeight: { fontWeight: "bold" },
  };

  const handlePaypalSubmit = async (data, actions) => {
    await handleDonateClick();
    return actions.order.create({
      purchase_units: [
        {
          description: "Corp Sponsorship - Dose",
          amount: {
            currency_code: "USD",
            value: getTotalAmount(),
          },
        },
      ],
    });
  };

  const handlePaypalSuccess = (info, orderId) => {
    // this.setState({ paypalSuccess: true });
    let details = {};
    authPost("/donate", {
      ...info,
      provider: "paypal",
      token: orderId,
    })
      .then(res => {
        if (res.status === 200) {
          details = {
            email: res.data.email,
            amt: res.data.amount,
            // date: res.data.date,
          };
        } else {
          let err = new Error(
            res.error.message ||
              (res.status
                ? `Donations endpoint returned "${res.status}: ${res.statusText}"`
                : null),
          );
          err.code = res.error.code;
          throw err;
        }
      })
      .catch(err => {
        console.error(
          "Error submitting Paypal order information to server.",
          err,
        );
      });
    updateDonorApiCall(donor, "complete");
    dispatch(DedicationActions.setDonateSuccess(true));
  };

  return (
    <div id="myid">
      <div>
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <span
            style={{
              width: "70%",
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            Additional Donation Amount: <br />
            <span style={{ fontSize: 14, color: "gray" }}>(Optional)</span>
          </span>
          <NumberFormat
            // thousandSeparator={true}
            decimalScale={0}
            fixedDecimalScale={true}
            customInput={TextField}
            className={classes.textField}
            name="additional_amount"
            style={{
              width: "28%",
              marginLeft: 12,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={handleDonorChange}
            isNumericString={true}
            variant="outlined"
            value={donor.additional_amount}
          />
        </div>
        <br />

        <div
          style={{
            marginTop: 20,
            padding: 16,
            borderRadius: 0,
            border: "1px solid black",
          }}
        >
          {Array(getMessageAndStatusPackageAmount())
            .fill()
            .map((_, i) => (
              <LineItem
                text="Package (Status + Message)"
                regPrice={messageAmount + statusAmount}
                price={packageAmount}
              />
            ))}
          {Array(getExtraStatusCount())
            .fill()
            .map((_, i) => (
              <LineItem key={i} text="Status" price={statusAmount} />
            ))}
          {Array(getExtraMessageCount())
            .fill()
            .map((_, i) => (
              <LineItem key={i} text="Message Ad" price={messageAmount} />
            ))}
          {getDiscountAmount() > 0 && (
            <LineItem text="DISCOUNT" price={-getDiscountAmount()} />
          )}
          {donor.additional_amount > 0 && (
            <div>
              <div
                style={{
                  borderRight: "1px solid black",
                  display: "inline-flex",
                  ...summaryItem.nameWidth,
                }}
              >
                <span
                  style={{
                    ...summaryItem.amountColor,
                    ...summaryItem.amountFontFamily,
                    ...summaryItem.fontSize,
                    ...summaryItem.fontWeight,
                  }}
                >
                  Additional Donation
                </span>
              </div>
              <div
                style={{
                  ...summaryItem.amountWidth,
                  borderRight: "unset",
                  justifyContent: "flex-end",
                  display: "inline-flex",
                }}
              >
                <span
                  style={{
                    ...summaryItem.amountColor,
                    ...summaryItem.amountFontFamily,
                    ...summaryItem.fontSize,
                    ...summaryItem.fontWeight,
                  }}
                >
                  ${parseFloat(donor.additional_amount)}
                </span>
              </div>
            </div>
          )}

          <div style={{ textAlign: "right" }}>
            <span
              style={{
                color: "rgba(0,0,0,0.8)",
                fontFamily: "Lato",
                fontSize: 24,
                fontWeight: "bold",
                marginRight: 20,
              }}
            >
              Total Donation&emsp;
            </span>
            {getFullPriceAmount() > getTotalAmount() && (
              <span
                style={{
                  color: "#1D78B9",
                  fontFamily: "Lato",
                  fontSize: 24,
                  fontWeight: "bold",
                  textDecoration: "line-through",
                  marginRight: 20,
                }}
              >
                ${getFullPriceAmount()}
              </span>
            )}
            <span
              style={{
                color: "#1D78B9",
                fontFamily: "Lato",
                fontSize: 24,
                fontWeight: "bold",
              }}
            >
              $
              <NumberFormat
                value={getTotalAmount()}
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
              />
            </span>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {!showCouponSection && !donor.discount_code_id ? (
          <p
            style={{
              cursor: "pointer",
            }}
            onClick={() => setShowCouponSection(true)}
          >
            Promo code?
          </p>
        ) : showCouponSection && !donor.discount_code_id ? (
          <div
            style={{
              margin: 20,
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              label="Enter promo code"
              variant="outlined"
              value={couponCode}
              onChange={e => setCouponCode(e.target.value)}
            />
            <Button
              size="small"
              style={{ marginLeft: 10 }}
              color="primary"
              onClick={() => checkCouponCode(couponCode)}
            >
              Apply
            </Button>
          </div>
        ) : donor.discount_code_id ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <p>
              Promo code <b>{donor.discount.discount_code.toUpperCase()}</b>{" "}
              applied
            </p>
            <HighlightOffIcon
              style={{ cursor: "pointer" }}
              color="error"
              onClick={() => {
                setDonor({ discount_code_id: undefined, discount: undefined });
                setCouponCode("");
              }}
            />
          </div>
        ) : null}
      </div>
      <div
        style={{
          textAlign: "center",
          fontFamily: "lato",
          color: "#555555",
          marginBottom: 16,
        }}
      >
        <span>
          Note: All orders are subject to approval.
          <br />
          Thank you! Your promotion is helping to continue
          <br />
          TorahAnytime's amazing service.
        </span>
      </div>
      <div style={{ textAlign: "center" }}>
        {donor.payment_type !== "paypal" ? (
          <Button
            style={{
              backgroundColor: "#E07020",
              color: "#FFFFFF",
              width: 280,
              height: 50,
              marginBottom: 35,
            }}
            onClick={handleDonateClick}
          >
            <span>Enter Card Info</span>
          </Button>
        ) : (
          <div
            style={{
              position: "relative",
              margin: true ? "0px auto 15px auto" : "40px 0 15px auto",
              minHeight: 68,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              {!corporateDonorIsValid(donor) && (
                <div
                  onClick={handleDonateClick}
                  style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    background: "white",
                    zIndex: 1000,
                    opacity: ".3",
                  }}
                ></div>
              )}
              <PayPalButton
                onError={err => {
                  updateDonorApiCall(donor, "payment error");
                  console.error("Paypal error", err);
                }}
                createOrder={handlePaypalSubmit}
                onSuccess={(details, data) => {
                  handlePaypalSuccess(details, data.orderID);
                }}
                onCancel={() => updateDonorApiCall(donor, "user cancelled")}
                options={{
                  clientId: REACT_APP_PAYPAL_CLIENT_ID,
                  // components: "",
                  intent: "capture",
                  disableFunding: "card,credit",
                }}
              />
            </div>
            <div
              // onMouseEnter={this.validatePaypal}
              style={{
                display: "block",
                position: "absolute",
                top: -15,
                bottom: 0,
                right: -15,
                left: -15,
                // zIndex: paypalReady ? 0 : 9999,
                zIndex: true ? 0 : 9999,
                // cursor: paypalReady ? "pointer" : "not-allowed",
                cursor: true ? "pointer" : "not-allowed",
              }}
            />
          </div>
        )}

        <ToastContainer />
        <StripeWrapper />
      </div>
    </div>
  );
};
