export const EmailEditorSelectors = {
  isLoading(state) {
    return state.emailEditor.loading;
  },
  isUploading(state) {
    return state.emailEditor.uploading;
  },
  serverUpToDate(state) {
    return state.emailEditor.serverUpToDate;
  },
  editorDesign(state) {
    return state.emailEditor.design;
  },
  editorHtml(state) {
    return state.emailEditor.html;
  },
  dataInitialized(state) {
    return state.emailEditor.initialized;
  },
  templateId(state) {
    return state.emailEditor.id;
  },
  templateSecret(state) {
    return state.emailEditor.secret;
  },
  error(state) {
    return {
      error: state.emailEditor.error,
      message: state.emailEditor.errorMessage,
    };
  },
};
