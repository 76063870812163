import React from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const TextFieldWithCharLimit = function(props) {
  const { muiInputBaseStyles = {}, ...rest } = props;
  const useStyles = makeStyles(theme => ({
    textarea: {
      "& .MuiFormHelperText-root": {
        position: "absolute",
        bottom: 5,
        right: 0,
      },
      "& .MuiInputBase-input": {
        ...muiInputBaseStyles,
      },
    },
  }));
  const classes = useStyles();

  return (
    <TextField
      className={classes.textarea}
      {...rest}
      inputProps={{ ...props.inputProps, maxLength: props.maxLength }}
      helperText={`${props.value?.length || 0}/${props.maxLength}`}
    />
  );
};
