import React from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, DedicationsSelectors } from "../state";
import { useMobile } from "../themes";
import { SelectCountry, SelectStateUS } from ".";

function _BillingDetails(props) {
  const {
    handleErrorOutline,
    handleBlur,
    handleFocus,
    handleDonorChange,
  } = props.globalItems;

  const isMobile = useMobile();
  const donor = useSelector(DedicationsSelectors.donor);

  const useStyles = makeStyles(theme => ({
    addAdditionalName: {
      backgroundColor: "#D8D8D8",
      color: "#1D78B9",
      padding: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: 18,
      fontWeight: 700,
      marginTop: 20,
      width: "65%",
      "&:hover": {
        opacity: 0.7,
      },
    },
    label: {
      backgroundColor: "white",
    },
    textField: {
      marginBottom: 10,
      minWidth: isMobile ? "49%" : 225,
      maxWidth: "49%",
      [`& fieldset`]: {
        borderRadius: 0,
      },
    },
    twoFields: {
      display: "flex",
      justifyContent: "space-between",
      [`& .MuiTextField-root`]: {
        minWidth: "49%",
        marginBottom: 10,
      },
    },
    fourFields: {
      display: "flex",
      justifyContent: "space-between",
      flexFlow: "row wrap",
      marginBottom: 5,
      [`& .MuiTextField-root`]: {
        minWidth: isMobile ? "49%" : donor.country === "US" ? "24%" : "32%",
        marginBottom: 10,
      },
    },
    inputLabel: {
      paddingLeft: 18.5,
    },
    headers: {
      display: "block",
      textAlign: "center",
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <h2 className={classes.headers}>Billing Address</h2>
      <div className={classes.twoFields}>
        <TextField
          className={classes.textField}
          name="address_line_1"
          autoComplete="address-line1"
          required
          error={handleErrorOutline("address_line_1")}
          onBlur={e => handleBlur(e)}
          onFocus={e => handleFocus(e)}
          onChange={handleDonorChange}
          label="Address Line 1"
          type="search"
          variant="outlined"
          value={donor.address_line_1}
        />
        <TextField
          className={classes.textField}
          name="address_line_2"
          autoComplete="address-line2"
          onChange={handleDonorChange}
          label="Address Line 2"
          type="search"
          variant="outlined"
          value={donor.address_line_2}
        />
      </div>
      <div className={classes.fourFields}>
        <TextField
          className={classes.textField}
          name="city"
          autoComplete="address-level2"
          required
          error={handleErrorOutline("city")}
          onBlur={e => handleBlur(e)}
          onFocus={e => handleFocus(e)}
          onChange={handleDonorChange}
          label="City"
          type="search"
          variant="outlined"
          value={donor.city}
        />
        {/* causing console error when selecting state */}
        {donor.country === "US" && (
          <SelectStateUS
            className={classes.textField}
            labelClassName={classes.inputLabel}
            name="state"
            autoComplete="address-level1"
            required
            error={handleErrorOutline("state")}
            onBlur={e => handleBlur(e)}
            onFocus={e => handleFocus(e)}
            onChange={handleDonorChange}
            label="State"
            variant="outlined"
            value={donor.state}
            disabled={donor.country !== "US"}
          />
        )}
        <TextField
          className={classes.textField}
          name="zip"
          autoComplete="postal-code"
          required
          error={handleErrorOutline("zip")}
          onBlur={e => handleBlur(e)}
          onFocus={e => handleFocus(e)}
          onChange={handleDonorChange}
          label="Zip Code"
          type="search"
          variant="outlined"
          value={donor.zip}
        />
        <SelectCountry
          className={classes.textField}
          labelClassName={classes.inputLabel}
          name="country"
          autoComplete="country"
          required
          error={handleErrorOutline("country")}
          onBlur={e => handleBlur(e)}
          onFocus={e => handleFocus(e)}
          onChange={handleDonorChange}
          label="Country"
          variant="outlined"
          value={donor.country}
        />
      </div>
    </div>
  );
}
export const BillingDetails = _BillingDetails;
