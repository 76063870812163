import React from "react";
import { useMobile } from "../../themes";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
const { REACT_APP_IMAGE_PATH } = process.env;

/**
 * @typedef {import("@material-ui/core").SelectProps} SelectProps
 *
 * @typedef {object} SelectStateUSProps
 * @property {string} [label]
 *
 * @param {SelectProps & SelectStateUSProps} props
 */
function _SelectionCard({
  details,
  isStatusSponsor,
  isActive,
  onClick,
  fontSize = 20,
  fontWeight = "bold",
  style,
  hasHelpIcon,
  helpIconAction,
  ...props
}) {
  const isMobile = useMobile();
  const imgName =
    details.icon_string && details.icon_string.length > 2
      ? details.icon_string
      : details.imgName;
  return (
    <div
      onClick={onClick}
      style={{
        cursor: "pointer",
        boxSizing: "border-box",
        width: "49%",
        marginTop: 3,
        marginBottom: 3,
        height: 55,
        border: "1px solid #D9D9D9",
        backgroundColor: isActive ? "rgb(29, 120, 185, .75)" : "#FFFFFF",
        padding: isMobile ? 5 : 10,
        display: "inline-flex",
        alignItems: "center",
        position: "relative",
        ...style,
      }}
    >
      {!!hasHelpIcon && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            right: 10,
          }}
          onClick={helpIconAction}
        >
          <HelpOutlineIcon color="primary" />
        </div>
      )}
      {/* not right way to omit image from type card but doing for now */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {details.imgName
          ? ""
          : (imgName && (
              <img
                style={{
                  width: 40,
                  paddingRight: isMobile ? 2 : 5,
                }}
                src={`${REACT_APP_IMAGE_PATH}/${imgName}`}
                alt=""
              />
            )) ||
            (details.icon_string && (
              <span role="img">{details.icon_string}</span>
            ))}
      </div>
      <div style={{ lineHeight: 1, textAlign: "center", width: "100%" }}>
        <span
          style={{
            color: isActive ? "#FFFFFF" : "#000",
            fontFamily: "Lato",
            fontSize: 15,
            fontWeight,
            whiteSpace: "pre-line",
          }}
        >
          {typeof details?.label === "string" &&
          details?.label?.split("<br>").length
            ? `${details?.label?.split("<br>").join("\n")}`
            : details.label}
        </span>
      </div>
    </div>
  );
}

export const SelectionCard = React.memo(_SelectionCard);
