import React, { useEffect, useState } from "react";

import axios from "axios";
import { toast } from "react-toastify";
import { Button, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";

import {
  UIActions,
  useDispatch,
  useSelector,
  DedicationsSelectors,
  DedicationActions,
  EmailEditorSelectors,
} from "../../../state";
import { corporateInfoIsValid } from "../../../lib/utils";
import { InformationalHeading } from "../../../components";

import { EmailEditorComponent } from "./EmailTemplateEditor";
import { MultiDateSelect } from "../../../components/MultiDateSelect";
import { Cart } from "./cart";

const { REACT_APP_API_URL } = process.env;

export const DailyDoseEmailCorporateSponsorship = () => {
  const [disabledDates, setDisabledDates] = useState(null);
  const [emailEditorIsValid, setEmailEditorIsValid] = useState(false);
  const donor = useSelector(DedicationsSelectors.donor);
  const apiError = useSelector(EmailEditorSelectors.error);
  const isUploading = useSelector(EmailEditorSelectors.isUploading);

  const dispatch = useDispatch();

  const cart = new Cart(donor);

  useEffect(() => {
    dispatch(
      UIActions.setDisplayTextItems({
        informationalHeading:
          "Have your Ad seen by over 120,000 email subscribers.<br/>Tzedakah/Ma’aser money can be used.",
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    axios
      .create({
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      })
      .get(`/corporate-email-dates`)
      .catch(err => {
        toast("An error occurred!", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
        throw err;
      })
      .then(result => {
        /**
         *`result.data` is an array of date strings
         * e.g.:
         * ```
         * ["2021-10-11"]
         * ```
         */
        setDisabledDates(new Set(result.data));

        console.log("loaded disabled dates");
        console.log({ disabledDates: result.data });
      });
  }, []);

  const setDonor = v =>
    dispatch(DedicationActions.setDonor({ ...donor, ...v }));

  const disableCheckoutButton =
    cart.cartIsEmpty() || !corporateInfoIsValid(donor) | !emailEditorIsValid;

  return (
    <>
      {apiError.error && (
        <div style={{ textAlign: "center" }}>{apiError.message}</div>
      )}
      {(!apiError.error || isUploading) && (
        <>
          <InformationalHeading />

          <div style={{ textAlign: "center" }}>
            <h2>Date Selection</h2>
            <p>Select the dates you would like to send the emails.</p>
            <p>Dates are only reserved upon completing payment.</p>
          </div>

          {disabledDates && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <MultiDateSelect
                donorDatesPropName="email_dates"
                globalItems={{ donor, setDonor }}
                disabledDates={disabledDates}
                shouldDisableDate={date => {
                  // We want to block out all Wednesdays
                  if (date.getDay() === 3) {
                    return true;
                  }

                  const in30Days = new Date();
                  in30Days.setDate(in30Days.getDate() + 30);

                  if (date > in30Days) {
                    return true;
                  }
                }}
                note={
                  <div
                    style={{
                      textAlign: "center",
                      padding: "0 20px",
                      margin: 0,
                    }}
                  >
                    <span>Dates that are not selectable are taken.</span>
                    <br />
                    <span>Dates are only available 30 days in advance.</span>
                  </div>
                }
              />
            </div>
          )}

          <h2 style={{ textAlign: "center" }}>Design The Email</h2>
          <div style={{ textAlign: "center" }}>
            <p>
              <strong>Copy the URL or bookmark this page</strong> to continue
              working on this page at a future time
            </p>
            <p>
              You can return to this URL at any time in the future to complete
              the transaction.
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Tooltip
              title={
                disableCheckoutButton && (
                  <>
                    <div>Please correct these issues:</div>
                    <ul>
                      {cart.cartIsEmpty() && (
                        <li>
                          You need to select a product. Hint: Did you select a
                          date to send the email?
                        </li>
                      )}
                      {!cart.cartIsEmpty() && !corporateInfoIsValid(donor) && (
                        <li>
                          Missing some information. Possibly on the WhatsApp
                          tab.
                        </li>
                      )}
                      {!emailEditorIsValid && (
                        <li>Please design the email in the section below</li>
                      )}
                    </ul>
                    <div>Please reach out to us if you need help.</div>
                  </>
                )
              }
            >
              <span>
                <Button
                  color="primary"
                  variant="contained"
                  component={Link}
                  disabled={disableCheckoutButton}
                  to="/corp-sponsor/checkout"
                >
                  Checkout
                </Button>
              </span>
            </Tooltip>
          </div>

          <EmailEditorComponent
            onValidation={isValid => setEmailEditorIsValid(isValid)}
          />
        </>
      )}
    </>
  );
};
