import React, { useState, useEffect } from "react";
import { useMobile } from "../../../themes";
import { corporateDonorIsValid, prepDonorForRequest } from "../../../lib";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import Button from "@material-ui/core/Button";

import { SampleDoseMessage } from "../../../components/input-groups/SampleDoseMessage";

// Local
import {
  InformationalHeading,
  SelectionCard,
  DailyDoseMessage,
  DailyDoseStatus,
} from "../../../components";
import {
  UIActions,
  useDispatch,
  useSelector,
  DedicationsSelectors,
  DedicationActions,
} from "../../../state";

import { corporateInfoIsValid } from "../../../lib/utils";

import { Link } from "react-router-dom";

import { Cart } from "./cart";

const { REACT_APP_API_URL } = process.env;

function _CorporateSponsorshipPage() {
  const [previewOpen, setPreviewOpen] = useState(false);
  const isMobile = useMobile();

  useEffect(() => {
    dispatch(
      UIActions.setDisplayTextItems({
        informationalHeading:
          "Have your Ad seen by over 30,000 WhatsApp subscribers.<br/>Tzedakah/Ma’aser money can be used.",
      }),
    );
    dispatch(
      DedicationActions.setDonor({ ...donor, is_corporate_sponsor: true }),
    );
    var s = document.createElement("script");
    s.setAttribute("src", "https://js.stripe.com/v3/");
    document.body.appendChild(s);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const donor = useSelector(DedicationsSelectors.donor);

  const cart = new Cart(donor);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setDonor = (d, deep = false) => {
    const newDonor = deep ? d : { ...donor, ...d };
    dispatch(DedicationActions.setDonor(newDonor));
  };

  const [initiated, setInitiated] = useState(false);
  useEffect(() => {
    if (!initiated && !donor.id && corporateDonorIsValid(donor)) {
      setInitiated(true);

      const totalAmount = cart.getTotalAmount();
      axios
        .create({
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        })
        .post("/dose-dedication", {
          donor: {
            ...prepDonorForRequest(donor),
            payment_status: "initiated",
            total_amount: totalAmount,
            additional_amount: donor.additional_amount || 0,
          },
        })

        .then(response => {
          dispatch(
            DedicationActions.setDonor({
              ...donor,
              id: response.data.donor_id,
            }),
          );
        })
        .catch(() => {
          if (donor.payment_type === "paypal") {
            toast("Paypal unavailable", {
              type: "error",
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
            });
            setDonor({ payment_type: "stripe", cannotDoPaypal: true });
          } else {
            setDonor({ cannotDoPaypal: true });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donor, initiated, dispatch, cart.getTotalAmount]);

  return (
    <div style={{ maxWidth: 960, margin: "0 auto", padding: 14 }}>
      <InformationalHeading />
      <h2 style={{ textAlign: "center" }}>Ad Placement Options</h2>
      <div
        style={{
          textAlign: "center",
          fontSize: 15,
          fontWeight: 700,
          fontFamily: "lato",
          color: "#555555",
          marginBottom: 10,
        }}
      >
        You can select one or both options.
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "baseline",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: isMobile ? "100%" : "45%",
            flexDirection: "column",
          }}
        >
          <SelectionCard
            style={{ width: "100%" }}
            details={{
              label: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {donor.is_message ? (
                    <CheckBoxOutlinedIcon style={{ marginRight: 10 }} />
                  ) : (
                    <CheckBoxOutlineBlankOutlinedIcon
                      style={{ marginRight: 10 }}
                    />
                  )}
                  Daily Dose Message
                </span>
              ),
            }}
            isActive={donor.is_message}
            onClick={() => {
              const isMessage = !donor.is_message;
              const donorToSet = {
                ...donor,
                is_message: isMessage,
              };
              if (!isMessage) {
                // setting back to defaults
                donorToSet.message_dates = [];
                donorToSet.link_to_action = "";
                donorToSet.message_text = "";
              }
              dispatch(DedicationActions.setDonor(donorToSet));
            }}
            hasHelpIcon={donor.is_message ? false : true}
            helpIconAction={e => {
              setPreviewOpen(true);
              e.stopPropagation();
            }}
          />
          <SampleDoseMessage
            setDialogOpen={setPreviewOpen}
            dialogOpen={previewOpen}
          />
          <DailyDoseMessage
            show={donor.is_message}
            previewOpen={previewOpen}
            setPreviewOpen={setPreviewOpen}
          />
        </div>
        <div
          style={{
            height: "90%",
            borderRight: "1px solid #e0e0e0",
            position: "absolute",
            top: "10%",
            left: "50%",
            display:
              !isMobile && (donor.is_message || donor.is_status)
                ? "block"
                : "none",
          }}
        >
          &nbsp;
        </div>
        <div
          style={{
            width: isMobile ? "100%" : "45%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <SelectionCard
            style={{ width: "100%" }}
            details={{
              label: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {donor.is_status ? (
                    <CheckBoxOutlinedIcon style={{ marginRight: 10 }} />
                  ) : (
                    <CheckBoxOutlineBlankOutlinedIcon
                      style={{ marginRight: 10 }}
                    />
                  )}
                  Daily Dose Status
                </span>
              ),
            }}
            isActive={donor.is_status}
            onClick={() => {
              const isStatus = !donor.is_status;
              const donorToSet = {
                ...donor,
                is_status: isStatus,
              };
              if (!isStatus) {
                // setting back to defaults
                donorToSet.status_dates = [];
                donorToSet.slide_1_type = "image";
                donorToSet.slide_2_type = "image";
                donorToSet.slide_1_file = undefined;
                donorToSet.slide_2_file = undefined;
                donorToSet.slide_1_text = "";
                donorToSet.slide_1_attachment_link = "";
                donorToSet.slide_2_attachment_link = "";
                donorToSet.slide_2_text = "";
              }

              dispatch(DedicationActions.setDonor(donorToSet));
            }}
          />
          <DailyDoseStatus show={donor.is_status} />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          component={Link}
          disabled={cart.cartIsEmpty() || !corporateInfoIsValid(donor)}
          to="/corp-sponsor/checkout"
        >
          Checkout
        </Button>
      </div>
    </div>
  );
}

export const DailyDoseWhatsAppCorpSponsorshipPage = React.memo(
  _CorporateSponsorshipPage,
);
