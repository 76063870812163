import { EmailEditorActions } from "./actions";

export const EmailEditorState = {
  name: "emailEditor",
  persist: false,
  defaults: {
    secret: null,
    id: null,
    error: false,
    errorMessage: "",
    loading: false,
    uploading: false,
    serverUpToDate: true,
    design: null,
    html: null,
    initialized: false,
  },
  handlers: {
    [EmailEditorActions.type.EMAIL_TEMPLATE_CREATE_REQUEST](state) {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    [EmailEditorActions.type.EMAIL_TEMPLATE_CREATE_SUCCESS](state, action) {
      const { id, secret } = action.payload;
      return {
        ...state,
        id,
        secret,
        loading: false,
        initialized: true,
      };
    },
    [EmailEditorActions.type.EMAIL_TEMPLATE_UPDATE_REQUEST](state, action) {
      const { design, html } = action.payload;
      return {
        ...state,
        uploading: true,
        serverUpToDate: false,
        design,
        html,
        error: false,
      };
    },
    [EmailEditorActions.type.EMAIL_TEMPLATE_UPDATE_SUCCESS](state) {
      return {
        ...state,
        uploading: false,
        serverUpToDate: true,
      };
    },
    [EmailEditorActions.type.EMAIL_TEMPLATE_GET_REQUEST](state, action) {
      const { id, secret } = action.payload;
      return {
        ...state,
        loading: true,
        id,
        secret,
        error: false,
      };
    },
    [EmailEditorActions.type.EMAIL_TEMPLATE_GET_SUCCESS](state, action) {
      const { design, html } = action.payload;

      return {
        ...state,
        loading: false,
        design,
        html,
        initialized: true,
      };
    },
    [EmailEditorActions.type.EMAIL_TEMPLATE_API_ERROR](state, action) {
      const { message } = action.payload;

      return {
        ...state,
        error: true,
        errorMessage: message,
      };
    },
  },
};
