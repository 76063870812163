import React from "react";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StatusSlide, MultiDateSelect } from "../../components";
import { Transition } from "react-transition-group";

import {
  useSelector,
  DedicationsSelectors,
  useDispatch,
  DedicationActions,
} from "../../state";

require("date-time-format-timezone");

export const DailyDoseStatus = function({ style, show }) {
  const dispatch = useDispatch();

  const donor = useSelector(DedicationsSelectors.donor);
  const setDonor = v =>
    dispatch(DedicationActions.setDonor({ ...donor, ...v }));

  const useStyles = makeStyles(theme => ({
    slideRadioOptions: {
      justifyContent: "space-between",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      marginBottom: 15,
      "& .MuiFormControlLabel-root": {
        width: "32%",
        margin: 0,
        padding: 0,
      },
    },
    slideTxtHelper: {
      display: "flex",
      justifyContent: "space-between",
    },
  }));
  const classes = useStyles();

  const defaultStyle = {
    ...style,
    textAlign: "center",
    padding: 5,
    overflow: "hidden",
    transition: "height 500ms ease-in-out",
    height: 0,
  };

  const transitionStyles = {
    entering: { height: 600 },
    entered: { height: "auto" },
    exiting: { height: 0 },
    exited: { height: 0 },
  };

  return (
    <Transition in={show} timeout={500}>
      {state => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <p style={{ fontSize: 12, textAlign: "left" }}>
            <b>Please note:</b> We recommend making your first slide a short
            video clip or image and your second slide text.
          </p>
          <div>
            <div>
              <h4 component="legend">Slide 1</h4>
              <StatusSlide slideNumber={1} />
            </div>
            <div>
              <h4 component="legend">Slide 2</h4>
              <StatusSlide slideNumber={2} />
            </div>
          </div>
          <MultiDateSelect
            donorDatesPropName="status_dates"
            globalItems={{ donor, setDonor }}
          />
          <h4 component="legend">Status post time</h4>
          <RadioGroup
            aria-setsize={6}
            aria-label="position"
            name="position"
            className={classes.slideRadioOptions}
            value={donor.post_time}
            onChange={e => {
              setDonor({
                ...donor,
                post_time: e.target.value,
              });
            }}
          >
            <FormControlLabel
              value="morning"
              control={<Radio size="small" color="primary" />}
              label="Morning"
            />
            <FormControlLabel
              value="afternoon"
              control={<Radio size="small" color="primary" />}
              label="Afternoon"
            />
            <FormControlLabel
              value="evening"
              control={<Radio size="small" color="primary" />}
              label="Evening"
            />
          </RadioGroup>
        </div>
      )}
    </Transition>
  );
};
