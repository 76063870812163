import React from "react";

// Local
import {
  Header,
  Footer,
  // ModalDialog,
} from "../components";
// import { InformationalCard } from "../components/cards/Informational";

function _MainLayout({ children }) {
  return (
    <>
      <Header />
      <div>{children}</div>
      <Footer />
    </>
  );
}

export const MainLayout = React.memo(_MainLayout);
