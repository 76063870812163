import React, { useState, useEffect, useRef } from "react";
import { useMobile } from "../../themes";
// import { useStyles } from "./DosePage.styles";
import {
  authPost,
  canContinueToDonor,
  donorIsValid,
  updateDonorApiCall,
  validateEmail,
  prepDonorForRequest,
} from "../../lib";
import NumberFormat from "react-number-format";
import {
  Button,
  TextField,
  Dialog,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { PayPalButton } from "react-paypal-button-v2";
// import { Elements, StripeProvider } from "react-stripe-elements";
import { StripeWrapper } from "../../components/common/Stripe";

// Local
import {
  SelectStateUS,
  DedicationCard,
  // ModalDialog,
} from "../../components";
// import { InformationalCard } from "../components/cards/Informational";
import {
  useDispatch,
  useSelector,
  DedicationsSelectors,
  DedicationActions,
} from "../../state";

import { SelectCountry } from "../../components/inputs/SelectCountry";
import { Redirect } from "react-router-dom";

const {
  REACT_APP_API_URL,
  REACT_APP_PAYPAL_CLIENT_ID_PRODUCTION,
  REACT_APP_PAYPAL_CLIENT_ID,
} = process.env;

function _DosePage() {
  const useStyles = makeStyles(theme => ({
    addAdditionalName: {
      backgroundColor: "#D8D8D8",
      color: "#1D78B9",
      // margin: 3,
      padding: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: 18,
      fontWeight: 700,
      marginTop: 20,
      width: "65%",
      "&:hover": {
        opacity: 0.7,
      },
    },
    label: {
      backgroundColor: "white",
    },
    textField: {
      [`& fieldset`]: {
        borderRadius: 0,
      },
    },
    twoFields: {
      display: "flex",
      justifyContent: "space-between",
    },
    inputLabel: {
      paddingLeft: 18.5,
    },
    headers: {
      display: "block",
      textAlign: "center",
    },
  }));
  const classes = useStyles();
  const sponsorshipTypes = {
    dose: {
      isStatusSponsor: false,
      label: "Dose Sponsor",
      imgName: "donat-illustration-type-card.png",
      amount: 52, // usually 52
    },
    status: {
      isStatusSponsor: true,
      label: "Status Sponsor",
      imgName: "Sponser-illustration-gold-type-card.png",
      amount: 101,
    },
  };

  const paymentTypes = [
    {
      name: "stripe",
      displayName: "Credit / Debit",
    },
    {
      name: "paypal",
      displayName: "Paypal",
    },
    {
      name: "phone",
      displayName: "By phone",
    },
    {
      name: "zelle",
      displayName: "Zelle Quickpay",
    },
  ];

  const [textFieldsTouched, setTextFieldsTouched] = useState([]);
  const [showErrorFields, setShowErrorFields] = useState(false);
  const [dedicationTypes, setDedicationTypes] = useState([{}]);
  // allCurrencyAmounts is retrieved from db but currency amounts can change as paypal and phone only offer USD
  const [currencyAmounts, setCurrencyAmounts] = useState([{}]);
  const [allCurrencyAmounts, setAllCurrencyAmounts] = useState();
  const currency = useSelector(DedicationsSelectors.currency);
  const setCurrency = curr => dispatch(DedicationActions.setCurrency(curr));
  const [moreDedicationTypes, setMoreDedicationTypes] = useState([{}]);
  const [paymentType, setPaymentType] = useState(paymentTypes[0]);
  useEffect(() => {
    var s = document.createElement("script");
    s.setAttribute("src", "https://js.stripe.com/v3/");
    document.body.appendChild(s);
  }, []);
  useEffect(() => {
    axios
      .create({
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      })
      .get("/dose-dedication-types")
      .then(result => {
        const primaryTypes = result.data.filter(t => t.primary_type);
        const secondaryTypes = result.data.filter(t => !t.primary_type);
        primaryTypes.push({
          label: "More",
          showMore: true,
          main: true,
          icon_string: "more.svg",
          id: -2,
        });
        setDedicationTypes(
          primaryTypes.sort((a, b) => a.position - b.position),
        );
        setMoreDedicationTypes(
          secondaryTypes.sort((a, b) => a.position - b.position),
        );
      });

    axios
      .create({
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      })
      .get("/dose-currencies-amounts")
      .then(result => {
        setAllCurrencyAmounts(result.data);
        setCurrencyAmounts(result.data);
        setCurrency(result.data.filter(c => c.id === "USD")[0]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const menuItems = currencyAmounts.map((currencyAmount, i) => (
    <MenuItem key={i} value={currencyAmount.id}>
      {currencyAmount.display_name}
    </MenuItem>
  ));
  const getDedicationAmount = dedication => {
    return (
      (dedication.isstatus ? getCurrencyAmountObj()?.status_amount ?? 0 : 0) +
      (dedication.isdose ? Number(getCurrencyAmountObj()?.dose_amount ?? 0) : 0)
    );
  };
  const getCurrencyAmountObj = () =>
    currencyAmounts.filter(c => c.id === donor.currency_id)[0]
      ?.dose_currencies_amount;

  const dispatch = useDispatch();
  const isMobile = useMobile();
  const myRef = useRef(null);
  const dedicationRef = useRef(null);
  const [cannotDoPaypal, setCannotDoPaypal] = useState(false);

  const dedicationCards = useSelector(DedicationsSelectors.allDedications);
  const filledDedications = useSelector(
    DedicationsSelectors.allFilledDedications,
  );
  const donor = useSelector(DedicationsSelectors.donor);
  const donateSuccess = useSelector(DedicationsSelectors.donateSuccess);

  const handlePaypalSubmit = async (data, actions) => {
    await handleDonateClick();
    return actions.order.create({
      purchase_units: [
        {
          description: "Daily Dose Dedication",
          amount: {
            // this should always be USD as setting to switch to USD if paypal
            currency_code: donor.currency_id.toUpperCase(),
            value: getTotalAmount(),
          },
        },
      ],
    });
  };

  const handlePaypalClose = () => {
    updateDonorApiCall(donor, "user cancelled");
  };
  const handleCurrencyChange = currencyId => {
    dispatch(DedicationActions.setDonor({ ...donor, currency_id: currencyId }));
    setCurrency(currencyAmounts.filter(c => c.id === currencyId)[0]);
  };
  const handleDonorChange = e => {
    const { name, value } = e.target;
    const updatedD = { ...donor };
    updatedD[name] = name !== "email" ? value : value.trim();
    dispatch(DedicationActions.setDonor(updatedD));
    window._loq = Array.isArray(window._loq) ? window._loq : [];
    let currCustomObj = window._loq.filter(e => e[0] === "custom")[0];
    if (currCustomObj) {
      currCustomObj[1] = updatedD;
    } else {
      window._loq.push(["custom", updatedD]);
    }
  };
  const handlePaymentTypeChange = paymentType => {
    let currency_id = donor.currency_id;
    if (paymentType.name === "paypal" || paymentType.name === "phone") {
      currency_id = "USD";
      setCurrency(currencyAmounts.filter(c => c.id === "USD")[0]);
      setCurrencyAmounts(currencyAmounts.filter(ca => ca.id === "USD"));
    } else {
      setCurrencyAmounts(allCurrencyAmounts);
    }
    setPaymentType(paymentType);
    dispatch(
      DedicationActions.setDonor({
        ...donor,
        currency_id,
        payment_type: paymentType.name,
      }),
    );
  };
  const goToDedication = () =>
    window.scrollTo(0, dedicationRef.current.offsetTop);

  const goToDonation = () => window.scrollTo(0, myRef.current.offsetTop);

  const handleDonateClick = async () => {
    if (!canContinueToDonor(dedicationCards)) {
      goToDedication();
      setShowErrorFields(true);
      return;
    }
    if (!donorIsValid(donor)) {
      goToDonation();
      setShowErrorFields(true);
      return;
    }
    const totalAmount = getTotalAmount();

    await dispatch(
      DedicationActions.setDonor({
        ...donor,
        payment_type: paymentType.name,
        total_amount: totalAmount,
        additional_amount: donor.additional_amount || 0,
      }),
    );
    const response = await axios
      .create({
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      })
      .post("/dose-dedication", {
        donor: {
          ...prepDonorForRequest(donor),
          payment_status: "pending",
          payment_type: paymentType.name,
          total_amount: totalAmount,
          additional_amount: donor.additional_amount || 0,
        },
        dedications: [].concat.apply(
          [],
          filledDedications.map(dc => ({
            is_dose: dc.isdose,
            is_status: dc.isstatus,
            dedication_name: dc.name,
            dedication_day: dc.day,
            dedication_type_id: dc.dedication_type_id,
          })),
        ),
      })
      .catch(err => {
        toast("An error occurred!", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
        throw err;
      });

    dispatch(
      DedicationActions.setDonor({
        ...donor,
        id: response.data.donor_id,
        payment_type: paymentType.name,
        total_amount: totalAmount,
        additional_amount: donor.additional_amount || 0,
      }),
    );

    if (paymentType.name === "stripe") {
      dispatch(
        DedicationActions.setDonor({
          ...donor,
          id: response.data.donor_id,
          payment_type: paymentType.name,
          total_amount: totalAmount,
          additional_amount: donor.additional_amount || 0,
          clientSecret: response.data.clientSecret,
        }),
      );
      dispatch(DedicationActions.setStripeOpen(true));
    } else if (paymentType.name === "phone" || paymentType.name === "zelle") {
      dispatch(DedicationActions.setDonateSuccess(true));
    }
  };
  const handleBlur = e => {
    setTextFieldsTouched([...textFieldsTouched, e.target.name]);
  };

  const handleFocus = e => {
    setTextFieldsTouched(textFieldsTouched.filter(f => f !== e.target.name));
  };
  const handleErrorOutline = name => {
    return (
      (showErrorFields || textFieldsTouched.includes(name)) &&
      (name !== "state" || donor.country === "US") &&
      (!donor[name] || (name === "email" && !validateEmail(donor[name])))
    );
  };

  const handlePaypalSuccess = (info, orderId) => {
    // this.setState({ paypalSuccess: true });
    let details = {};
    authPost("/donate", {
      ...info,
      provider: "paypal",
      token: orderId,
    })
      .then(res => {
        if (res.status === 200) {
          details = {
            email: res.data.email,
            amt: res.data.amount,
            // date: res.data.date,
          };
        } else {
          let err = new Error(
            res.error.message ||
            (res.status
              ? `Donations endpoint returned "${res.status}: ${res.statusText}"`
              : null),
          );
          err.code = res.error.code;
          throw err;
        }
      })
      .catch(err => {
        console.error(
          "Error submitting Paypal order information to server.",
          err,
        );
      });
    updateDonorApiCall(donor, "complete");
    dispatch(DedicationActions.setDonateSuccess(true));
  };
  const firstSecond = number =>
    `${number}${number % 10 === 1 && number % 100 !== 11
      ? "st"
      : number % 10 === 2 && number % 100 !== 12
        ? "nd"
        : number % 10 === 3 && number % 100 !== 13
          ? "rd"
          : "th"
    }`;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getTotalAmount = () =>
    filledDedications
      .map(dc => getDedicationAmount(dc))
      .reduce((a, b) => a + b, 0) + Number(donor.additional_amount ?? 0);

  // did as object for now as want to use multiple
  const summaryItem = {
    fontSize: { fontSize: 14 },
    nameFontFamily: { fontFamily: ".SF NS Display" },
    amountFontFamily: { fontFamily: "Lato" },
    amountColor: { color: "#1D78B9" },
    nameWidth: { width: "75%" },
    amountWidth: { width: "25%" },
    amountFontWeight: { fontWeight: "bold" },
  };

  const [initiated, setInitiated] = useState(false);
  useEffect(() => {
    if (
      !initiated &&
      !donor.id &&
      canContinueToDonor(dedicationCards) &&
      donorIsValid(donor)
    ) {
      setInitiated(true);

      const totalAmount = getTotalAmount();
      axios
        .create({
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        })
        .post("/dose-dedication", {
          donor: {
            ...prepDonorForRequest(donor),
            payment_status: "initiated",
            payment_type: paymentType.name,
            total_amount: totalAmount,
            additional_amount: donor.additional_amount || 0,
          },
          dedications: [].concat.apply(
            [],
            filledDedications.map(dc => ({
              is_dose: dc.isdose,
              is_status: dc.isstatus,
              dedication_name: dc.name,
              dedication_day: dc.day,
              dedication_type_id: dc.dedication_type_id,
            })),
          ),
        })

        .then(response => {
          dispatch(
            DedicationActions.setDonor({
              ...donor,
              id: response.data.donor_id,
            }),
          );
        })
        .catch(err => {
          // if not paypal, can continue and will just fail when it tries to do the request on pending, but for paypal there can be issues if we don't have the id beforehand
          if (paymentType.name === "paypal") {
            toast("Paypal unavailable", {
              type: "error",
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
            });
            setPaymentType(paymentTypes[0]);
          }
          setCannotDoPaypal(true);
        });
    }
  }, [
    donor,
    paymentType,
    dedicationCards,
    initiated,
    filledDedications,
    paymentTypes,
    dispatch,
    getTotalAmount,
  ]);

  return (
    <div ref={dedicationRef}>
      {donateSuccess && (
        <Redirect to={`/dose-sponsor/thankyou/${paymentType.name}`} />
      )}
      <DedicationCard
        dedication={dedicationCards[dedicationCards.length - 1]}
        dedicationTypes={dedicationTypes}
        moreDedicationTypes={moreDedicationTypes}
        sponsorshipTypes={sponsorshipTypes}
        index={dedicationCards.length - 1}
        getFirstSecond={firstSecond}
        showErrorFields={showErrorFields}
      />
      <div
        style={{
          maxWidth: 650,
          margin: "0 auto",
          padding: 14,
        }}
      >
        <h2 className={classes.headers} style={{ marginTop: 0 }}>
          Donation Method
        </h2>
        <div style={{ textAlign: "center" }}>
          {paymentTypes.map(
            (pt, i) =>
              (pt.name !== "paypal" || !cannotDoPaypal) && (
                <Button
                  key={i}
                  className={classes.textField}
                  style={{
                    height: 62,
                    width: isMobile ? "50%" : cannotDoPaypal ? "33%" : "25%",
                    backgroundColor:
                      paymentType.name === pt.name ? "#1D78B9" : "white",
                    color: paymentType.name === pt.name ? "white" : "#424848",
                    fontFamily: "Lato",
                    fontWeight: "bold",
                    borderRadius: 0,
                    borderShadow: "none",
                    border: "1px #D9D9D9 solid",
                  }}
                  onClick={() => handlePaymentTypeChange(pt)}
                >
                  {pt.displayName}
                </Button>
              ),
          )}
        </div>
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            marginTop: 16,
          }}
          label="Currency"
          name="currency_id"
          onChange={e => handleCurrencyChange(e.target.value)}
          SelectProps={{ displayEmpty: true }}
          select
          value={donor.currency_id}
          variant="outlined"
        >
          {menuItems}
        </TextField>
        <h2 className={classes.headers} ref={myRef}>
          Donor Details
        </h2>
        <div className={classes.twoFields}>
          <TextField
            className={classes.textField}
            name="first_name"
            autoComplete="given-name"
            required
            error={handleErrorOutline("first_name")}
            onBlur={e => handleBlur(e)}
            onFocus={e => handleFocus(e)}
            style={{ width: "48%", marginTop: 6, marginBottom: 6 }}
            onChange={handleDonorChange}
            label="First Name"
            type="search"
            variant="outlined"
            value={donor.first_name}
          />
          <TextField
            className={classes.textField}
            name="last_name"
            autoComplete="family-name"
            required
            error={handleErrorOutline("last_name")}
            onBlur={e => handleBlur(e)}
            onFocus={e => handleFocus(e)}
            style={{ width: "48%", marginTop: 6, marginBottom: 6 }}
            onChange={handleDonorChange}
            label="Last Name"
            type="search"
            variant="outlined"
            value={donor.last_name}
          />
        </div>
        <TextField
          className={classes.textField}
          name="phone"
          autoComplete="tel"
          required
          error={handleErrorOutline("phone")}
          onBlur={e => handleBlur(e)}
          onFocus={e => handleFocus(e)}
          style={{ width: "100%", marginTop: 6, marginBottom: 6 }}
          onChange={handleDonorChange}
          label="Your Whatsapp Number"
          type="search"
          variant="outlined"
          value={donor.phone}
        />
        <TextField
          className={classes.textField}
          name="email"
          autoComplete="email"
          required
          error={handleErrorOutline("email")}
          helperText={
            donor.email &&
            !validateEmail(donor.email) &&
            "Please enter a valid email address"
          }
          onBlur={e => handleBlur(e)}
          onFocus={e => handleFocus(e)}
          style={{ width: "100%", marginTop: 6, marginBottom: 6 }}
          onChange={handleDonorChange}
          label="Email Address"
          type="search"
          variant="outlined"
          value={donor.email}
        />
        <h2 className={classes.headers}>Billing Address</h2>
        <TextField
          className={classes.textField}
          name="address_line_1"
          autoComplete="address-line1"
          required
          error={handleErrorOutline("address_line_1")}
          onBlur={e => handleBlur(e)}
          onFocus={e => handleFocus(e)}
          style={{ width: "100%", marginTop: 6, marginBottom: 6 }}
          onChange={handleDonorChange}
          label="Billing Address Line 1"
          type="search"
          variant="outlined"
          value={donor.address_line_1}
        />
        <TextField
          className={classes.textField}
          name="address_line_2"
          autoComplete="address-line2"
          style={{ width: "100%", marginTop: 6, marginBottom: 6 }}
          onChange={handleDonorChange}
          label="Billing Address Line 2"
          type="search"
          variant="outlined"
          value={donor.address_line_2}
        />
        <div className={classes.twoFields}>
          <TextField
            className={classes.textField}
            name="city"
            autoComplete="address-level2"
            required
            error={handleErrorOutline("city")}
            onBlur={e => handleBlur(e)}
            onFocus={e => handleFocus(e)}
            style={{
              width: !isMobile ? "50%" : "66%",
              marginTop: 6,
              marginBottom: 6,
              marginRight: !isMobile ? 20 : 0,
            }}
            onChange={handleDonorChange}
            label="City"
            type="search"
            variant="outlined"
            value={donor.city}
          />
          {/* causing console error when selecting state */}
          <SelectStateUS
            className={classes.textField}
            labelClassName={classes.inputLabel}
            name="state"
            autoComplete="address-level1"
            required
            error={handleErrorOutline("state")}
            onBlur={e => handleBlur(e)}
            onFocus={e => handleFocus(e)}
            style={{
              width: !isMobile ? "50%" : "30%",
              marginTop: 6,
              marginBottom: 6,
            }}
            onChange={handleDonorChange}
            label="State"
            variant="outlined"
            value={donor.state}
            disabled={donor.country !== "US"}
          />
        </div>
        <div className={classes.twoFields}>
          <TextField
            className={classes.textField}
            name="zip"
            autoComplete="postal-code"
            required
            error={handleErrorOutline("zip")}
            onBlur={e => handleBlur(e)}
            onFocus={e => handleFocus(e)}
            style={{
              width: !isMobile ? "23%" : "48%",
              marginTop: 6,
              marginBottom: 6,
              marginRight: !isMobile ? 20 : 0,
            }}
            onChange={handleDonorChange}
            label="Zip Code"
            type="search"
            variant="outlined"
            value={donor.zip}
          />
          <SelectCountry
            className={classes.textField}
            labelClassName={classes.inputLabel}
            name="country"
            autoComplete="country"
            required
            error={handleErrorOutline("country")}
            onBlur={e => handleBlur(e)}
            onFocus={e => handleFocus(e)}
            style={{
              width: !isMobile ? "100%" : "48%",
              marginTop: 6,
              marginBottom: 6,
            }}
            onChange={handleDonorChange}
            label="Country"
            variant="outlined"
            value={donor.country}
          />
        </div>
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <span
            style={{
              width: "70%",
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            Additional Donation Amount: <br />
            <span style={{ fontSize: 14, color: "gray" }}>(Optional)</span>
          </span>
          <NumberFormat
            // thousandSeparator={true}
            decimalScale={0}
            fixedDecimalScale={true}
            customInput={TextField}
            className={classes.textField}
            name="additional_amount"
            style={{
              width: "28%",
              marginLeft: 12,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {currency?.symbol ?? ""}
                </InputAdornment>
              ),
            }}
            onChange={handleDonorChange}
            isNumericString={true}
            variant="outlined"
            value={donor.additional_amount}
          />
        </div>
        <br />

        <div
          style={{
            marginTop: 20,
            padding: 16,
            borderRadius: 0,
            border: "1px solid black",
          }}
        >
          {filledDedications.map((dc, index) => {
            const st = {
              width: "50%",
              borderRight: "1px solid black",
              display: "inline-flex",
            };

            return (
              <div key={index}>
                <div style={{ ...st, ...summaryItem.nameWidth }}>
                  <span
                    style={{
                      ...summaryItem.fontSize,
                      ...summaryItem.nameFontFamily,
                    }}
                  >
                    {firstSecond(index + 1)} Dedication
                  </span>
                </div>
                {/* <div style={{ ...st }}>
                  <span style={{ fontSize, fontFamily, marginLeft: 8 }}>
                    {`${dc.isdose ? "Dose" : ""}${
                      dc.isdose && dc.isstatus ? " + " : ""
                    }${dc.isstatus ? "Status" : ""}`}
                  </span>
                </div> */}
                <div
                  style={{
                    ...st,
                    ...summaryItem.amountWidth,
                    borderRight: "unset",
                    justifyContent: "flex-end",
                  }}
                >
                  <span
                    style={{
                      ...summaryItem.amountColor,
                      ...summaryItem.amountFontFamily,
                      ...summaryItem.fontSize,
                      ...summaryItem.fontWeight,
                    }}
                  >
                    {currency?.symbol ?? ""}
                    {getDedicationAmount(dc)}
                  </span>
                </div>
              </div>
            );
          })}
          {donor.additional_amount > 0 && (
            <div>
              <div
                style={{
                  borderRight: "1px solid black",
                  display: "inline-flex",
                  ...summaryItem.nameWidth,
                }}
              >
                <span
                  style={{
                    ...summaryItem.amountColor,
                    ...summaryItem.amountFontFamily,
                    ...summaryItem.fontSize,
                    ...summaryItem.fontWeight,
                  }}
                >
                  Additional Donation
                </span>
              </div>
              <div
                style={{
                  ...summaryItem.amountWidth,
                  borderRight: "unset",
                  justifyContent: "flex-end",
                  display: "inline-flex",
                }}
              >
                <span
                  style={{
                    ...summaryItem.amountColor,
                    ...summaryItem.amountFontFamily,
                    ...summaryItem.fontSize,
                    ...summaryItem.fontWeight,
                  }}
                >
                  {currency?.symbol ?? ""}
                  {parseFloat(donor.additional_amount)}
                </span>
              </div>
            </div>
          )}
          {canContinueToDonor(dedicationCards) && (
            <div>
              <div style={{ width: "75%", marginTop: 25 }}>
                <span
                  style={{
                    ...summaryItem.fontSize,
                    ...summaryItem.fontFamily,
                    ...summaryItem.amountColor,
                    cursor: "pointer",
                  }}
                  onClick={() => goToDedication()}
                >
                  Add another dedication
                </span>
              </div>
              <hr
                style={{
                  backgroundColor: "black",
                  height: 2,
                  border: 0,
                }}
              />
            </div>
          )}
          <div style={{ textAlign: "right" }}>
            <span
              style={{
                color: "rgba(0,0,0,0.8)",
                fontFamily: "Lato",
                fontSize: 24,
                fontWeight: "bold",
              }}
            >
              Total Donation&emsp;
            </span>
            <span
              style={{
                color: "#1D78B9",
                fontFamily: "Lato",
                fontSize: 24,
                fontWeight: "bold",
              }}
            >
              {currency?.symbol ?? ""}
              <NumberFormat
                value={getTotalAmount()}
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
              />
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          fontFamily: "lato",
          color: "#555555",
          marginBottom: 16,
        }}
      >
        <span>
          All your donations help to continue
          <br />
          this Amazing Daily Dose project.
        </span>
      </div>
      <div style={{ textAlign: "center" }}>
        {paymentType.name !== "paypal" ? (
          <Button
            style={{
              backgroundColor: "#E07020",
              color: "#FFFFFF",
              width: 280,
              height: 50,
              marginBottom: 35,
            }}
            onClick={handleDonateClick}
          >
            <span>
              {paymentType.name === "stripe" ? "Enter Card Info" : "Donate"}
            </span>
          </Button>
        ) : (
          <div
            style={{
              position: "relative",
              margin: true ? "0px auto 15px auto" : "40px 0 15px auto",
              minHeight: 68,
            }}
          >
            {/* <Dialog open={paypalStart}> */}
            <Dialog open={false}>
              {/* {paypalSuccess && ( */}
              {false && (
                <div className="stripe-checkout">
                  <div
                    style={{
                      fontSize: 25,
                      fontWeight: 600,
                      color: "#555",
                      marginBottom: 20,
                    }}
                  >
                    <div>Completing transaction...</div>
                    {!REACT_APP_PAYPAL_CLIENT_ID_PRODUCTION && (
                      <div style={{ marginTop: 20, color: "red" }}>
                        ***TEST MODE***
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Dialog>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              {(!canContinueToDonor(dedicationCards) ||
                !donorIsValid(donor)) && (
                  <div
                    onClick={handleDonateClick}
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      background: "white",
                      zIndex: 1000,
                      opacity: ".3",
                    }}
                  ></div>
                )}
              <PayPalButton
                // id="my-paypal-id"
                // disabled={true}
                onError={err => {
                  console.error("Paypal error", err);
                }}
                createOrder={handlePaypalSubmit}
                onSuccess={(details, data) => {
                  handlePaypalSuccess(details, data.orderID);
                }}
                onCancel={handlePaypalClose}
                options={{
                  clientId: REACT_APP_PAYPAL_CLIENT_ID,
                  // components: "",
                  intent: "capture",
                  disableFunding: "card,credit",
                }}
              />
            </div>
            <div
              // onMouseEnter={this.validatePaypal}
              style={{
                display: "block",
                position: "absolute",
                top: -15,
                bottom: 0,
                right: -15,
                left: -15,
                // zIndex: paypalReady ? 0 : 9999,
                zIndex: true ? 0 : 9999,
                // cursor: paypalReady ? "pointer" : "not-allowed",
                cursor: true ? "pointer" : "not-allowed",
              }}
            />
          </div>
        )}

        <ToastContainer />

        {/* <ModalDialog
        children={children}
        style={{ color: "red" }}
        open={modal.open}
        // title={paymentType.modalContent}
        onClose={() => setModal({ ...modal, open: false })}
        handleCancel={setModal}
        paymentType={paymentType}
        amount={
          dedicationCards
            .map(dc => dc.getTotalAmount())
            .reduce((a, b) => a + b, 0) * 100
        }
      /> */}
        <StripeWrapper />
      </div>
    </div>
  );
}

export const DosePage = React.memo(_DosePage);
