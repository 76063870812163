import React, { useState, useRef, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import {
  DonorDetails,
  BillingDetails,
  DonationSection,
  PaymentTypes,
} from "../../../components";

import {
  useSelector,
  useDispatch,
  DedicationActions,
  DedicationsSelectors,
  EmailEditorSelectors,
} from "../../../state";

import {
  validateEmail,
  corporateDonorIsValid,
  prepDonorForRequest,
} from "../../../lib";

import { Cart } from "./cart";

const { REACT_APP_API_URL } = process.env;

export const CheckoutPage = () => {
  const dispatch = useDispatch();

  const donor = useSelector(DedicationsSelectors.donor);
  const emailEditorHtml = useSelector(EmailEditorSelectors.editorHtml);
  const emailTemplateId = useSelector(EmailEditorSelectors.templateId);
  const donateSuccess = useSelector(DedicationsSelectors.donateSuccess);

  const [textFieldsTouched, setTextFieldsTouched] = useState([]);
  const [showErrorFields, setShowErrorFields] = useState(false);
  const [initiated, setInitiated] = useState(false);

  const donorRef = useRef(null);
  const sponsorshipRef = useRef(null);

  const calculator = new Cart(donor);

  const setDonor = v =>
    dispatch(DedicationActions.setDonor({ ...donor, ...v }));

  useEffect(() => {
    if (!initiated && !donor.id && corporateDonorIsValid(donor)) {
      setInitiated(true);

      axios
        .create({
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        })
        .post("/dose-dedication", {
          donor: {
            ...prepDonorForRequest(donor),
            payment_status: "initiated",
            total_amount: calculator.getTotalAmount(),
            additional_amount: donor.additional_amount || 0,
          },
        })

        .then(response => {
          dispatch(
            DedicationActions.setDonor({
              ...donor,
              id: response.data.donor_id,
            }),
          );
        })
        .catch(() => {
          if (donor.payment_type === "paypal") {
            toast("Paypal unavailable", {
              type: "error",
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
            });
            dispatch(
              DedicationActions.setDonor({
                ...donor,
                payment_type: "stripe",
                cannotDoPaypal: true,
              }),
            );
          } else {
            dispatch(
              DedicationActions.setDonor({ ...donor, cannotDoPaypal: true }),
            );
          }
        });
    }
  }, [donor, initiated, dispatch, calculator]);

  const handleDonorChange = e => {
    const { name, value } = e.target;
    const updatedD = { ...donor };
    updatedD[name] = name !== "email" ? value : value.trim();
    dispatch(DedicationActions.setDonor(updatedD));
    window._loq = Array.isArray(window._loq) ? window._loq : [];
    let currCustomObj = window._loq.filter(e => e[0] === "custom")[0];
    if (currCustomObj) {
      currCustomObj[1] = updatedD;
    } else {
      window._loq.push(["custom", updatedD]);
    }
  };

  const handleBlur = e => {
    setTextFieldsTouched([...textFieldsTouched, e.target.name]);
  };

  const handleFocus = e => {
    setTextFieldsTouched(textFieldsTouched.filter(f => f !== e.target.name));
  };
  const handleErrorOutline = name => {
    return (
      (showErrorFields || textFieldsTouched.includes(name)) &&
      (name !== "state" || donor.country === "US") &&
      (!donor[name] || (name === "email" && !validateEmail(donor[name])))
    );
  };

  if (donor.email_dates.length > 0 && donor.email_html !== emailEditorHtml) {
    setDonor({
      email_html: emailEditorHtml,
      email_template_id: emailTemplateId,
    });
  }

  return (
    <div>
      {donateSuccess && <Redirect to={`/corp-sponsor/thankyou`} />}
      {calculator.cartIsEmpty() && <Redirect to="/corp-sponsor" />}
      <div ref={sponsorshipRef}></div>
      <PaymentTypes globalItems={{ donor, setDonor }} />
      <div ref={donorRef}></div>
      <DonorDetails
        globalItems={{
          handleErrorOutline,
          handleBlur,
          handleFocus,
          handleDonorChange,
        }}
        donorRef={donorRef}
      />
      <BillingDetails
        globalItems={{
          handleErrorOutline,
          handleBlur,
          handleFocus,
          handleDonorChange,
        }}
      />
      <DonationSection
        globalItems={{
          handleDonorChange,
          donor,
          getTotalAmount: calculator.getTotalAmount,
          setShowErrorFields,
          setDonor,
          getMessageAndStatusPackageAmount:
            calculator.getMessageAndStatusPackageAmount,
          getExtraMessageCount: calculator.getExtraMessageCount,
          getExtraStatusCount: calculator.getExtraStatusCount,
          packageAmount: calculator.packageAmount,
          messageAmount: calculator.messageAmount,
          statusAmount: calculator.statusAmount,
        }}
        goToSponsorshipRef={() =>
          window.scrollTo(0, sponsorshipRef.current.offsetTop)
        }
        goToDonorRef={() => window.scrollTo(0, donorRef.current.offsetTop)}
        getPrediscountAmount={calculator.getPrediscountAmount}
        getDiscountAmount={calculator.getDiscountAmount}
        getFullPriceAmount={calculator.getFullPriceAmount}
      />
    </div>
  );
};
