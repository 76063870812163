import React from "react";
import { useSelector, UISelectors } from "../state";

export const InformationalHeading = function() {
  const displayTextItems = useSelector(UISelectors.displayTextItems);
  return (
    <div
      style={{
        textAlign: "center",
        fontFamily: "lato",
        color: "#555555",
        padding: 14,
        fontSize: 15,
        fontWeight: 700,
      }}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: displayTextItems.informationalHeading,
        }}
      ></span>
    </div>
  );
};
