import React from "react";
import logo from "../../assets/img/TA-logo.png";
import logoMobile from "../../assets/img/TA-Square-Logo.png";
import { useMobile } from "../../themes";

function _Footer({ ...props }) {
  const isMobile = useMobile();

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#1D78B9",
        fontSize: 26,
        padding: !isMobile ? 20 : 5,
        alignItems: "center",
        fontWeight: 700,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img
        style={{ maxWidth: "100%", height: 35 }}
        src={isMobile ? logoMobile : logo}
        alt="logo"
      />
    </div>
  );
}

export const Footer = React.memo(_Footer);
