import { DedicationActions } from "./actions";

/**
 * Todo state (**NOT** persisted).
 * @example
 * {
 *    items: [
 *      { id: 1, title: "", done: false, },
 *      { id: 2, title: "", done: false, },
 *      { id: 3, title: "", done: false, },
 *    ],
 * }
 */

const emptyDedication = {
  dedicationType: {},
  name: "",
  day: null,
  isdose: true,
};
export const DedicationState = {
  name: "dedications",
  persist: false,
  defaults: {
    donor: {
      first_name: "",
      last_name: "",
      currency_id: "USD",
      email: "",
      phone: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      zip: "",
      country: "US",
      slide_1_type: "image",
      slide_2_type: "image",
      message_dates: [],
      status_dates: [],
      lecture: {},
      payment_type: "stripe",
      link_to_action: "",
      post_time: "morning",
      email_dates: [],
      email_html: "",
    },
    items: [
      {
        dedicationType: {
          icon_string: "",
          id: null,
          label: "",
          primary_type: true,
        },
        name: "",
        day: undefined,
        isdose: true,
        dedication_type_id: undefined,
      },
    ],
    currency: {},
    stripeOpen: false,
    donateSuccess: false,
    lectureAlreadySponsored: false,
    initiatedCorporateSponsorship: false,
  },
  handlers: {
    [DedicationActions.type.UPDATE_ACTIVE_DEDICATION](state, { item }) {
      // #region NOTE: The need to copy the entire array and find an index
      // is one reason why it's recommended to store lists as an object, e.g.
      // items: {
      //   1: {id:1,title:"",done:false},
      //   2: {id:2,title:"",done:false},
      //   3: {id:3,title:"",done:false},
      // }
      // And then store the sorted ids as an array, e.g.
      // sortedItemIds: [3, 2, 1]
      // #endregion
      const items = [...state.items];
      // const index = items.findIndex(it => it.index === item.index);
      items[items.length - 1] = item;
      return {
        ...state,
        items,
      };
    },
    [DedicationActions.type.START_NEW_DEDICATION](state) {
      const items = [...state.items];
      items.push(emptyDedication);
      return {
        ...state,
        items,
      };
    },
    [DedicationActions.type.REMOVE_DEDICATION](state, { index }) {
      const items = [...state.items];
      items.splice(index, 1);
      return {
        ...state,
        items,
      };
    },
    [DedicationActions.type.SET_STRIPE_OPEN](state, stripeOpen) {
      return {
        ...state,
        stripeOpen: stripeOpen.open,
      };
    },
    [DedicationActions.type.SET_LECTURE_ALREADY_SPONSORED](state, sponsored) {
      return {
        ...state,
        lectureAlreadySponsored: sponsored,
      };
    },
    [DedicationActions.type.SET_DONATE_SUCCESS](state, donateSuccess) {
      return {
        ...state,
        donateSuccess: donateSuccess.success,
      };
    },
    [DedicationActions.type.SET_DONOR](state, donor) {
      return {
        ...state,
        donor: donor.donor,
      };
    },
    [DedicationActions.type.SET_CURRENCY](state, currency) {
      return {
        ...state,
        currency: currency.currency,
      };
    },
  },
};
