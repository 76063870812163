import React, { useState } from "react";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Button } from "@material-ui/core";
import format from "date-fns/format";
import MultipleDatesPicker from "@bitbean/material-ui-multiple-dates-picker";
require("date-time-format-timezone");

export const MultiDateSelect = function(props) {
  // not the best way to have disabled dates as an empty set, better to not call the function 'shouldDisableDate' at all if empty but doing like this for now
  const {
    donorDatesPropName,
    disabledDates = new Set([]),
    shouldDisableDate = () => false,
  } = props;
  const {
    globalItems: { donor, setDonor },
    note,
  } = props;
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  return (
    <div style={{ marginTop: 15 }}>
      <Button
        fullWidth
        onClick={() => setOpen(!open)}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{
          backgroundColor: hovered ? "#3f51b5" : undefined,
        }}
      >
        Select{donor[donorDatesPropName].length ? " more" : ""} Dates
      </Button>
      <MultipleDatesPicker
        shouldColorToday
        note={note}
        shouldDisableDate={date =>
          date < new Date() ||
          disabledDates.has(format(date, "yyyy-MM-dd")) ||
          shouldDisableDate(date)
        }
        width={300}
        height={300}
        format={"MM/dd"}
        name="day"
        required
        open={open}
        selectedDates={donor[donorDatesPropName]}
        onCancel={() => setOpen(false)}
        onSubmit={dates => {
          setOpen(false);
          console.log("selected dates", dates);

          setDonor({
            [donorDatesPropName]: dates,
          });
        }}
      />
      {donor[donorDatesPropName].map((d, i) => (
        <div
          key={i}
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          <span style={{ width: 130 }}>{new Date(d).toDateString()}</span>
          <HighlightOffIcon
            style={{ cursor: "pointer", float: "right" }}
            color={"error"}
            onClick={() =>
              setDonor({
                [donorDatesPropName]: donor[donorDatesPropName].filter(
                  (_d, di) => di !== i,
                ),
              })
            }
          />
        </div>
      ))}
    </div>
  );
};
