import React from "react";
import logo from "../../assets/img/TA-logo.png";
import logoMobile from "../../assets/img/TA-Square-Logo.png";
import { makeStyles } from "@material-ui/core";
import { useMobile } from "../../themes";
import { UISelectors, useSelector } from "../../state";

function _Header({ ...props }) {
  const isMobile = useMobile();
  const useStyles = makeStyles(theme => ({
    divSpacing: {
      margin: isMobile ? 5 : 10,
      marginTop: isMobile ? 0 : 10,
    },
  }));
  const classes = useStyles();
  const headerText = useSelector(UISelectors.headerText);
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#1D78B9",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        fontSize: 24,
        padding: 5,
        paddingTop: 10,
        alignItems: "center",
        fontWeight: 700,
      }}
    >
      <div
        className={classes.divSpacing}
        style={{
          display: "flex",
          alignItems: "center",
          maxWidth: "18%",
        }}
      >
        <img
          style={{ maxWidth: "100%" }}
          src={isMobile ? logoMobile : logo}
          alt="logo"
        />
      </div>
      <div className={classes.divSpacing} style={{ color: "white" }}>
        |
      </div>
      <div
        className={classes.divSpacing}
        style={{
          color: "white",
        }}
      >
        {headerText}
      </div>
    </div>
  );
}

export const Header = React.memo(_Header);
