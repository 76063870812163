import React, { useState } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import { useMobile } from "../themes";

import {
  DedicationsSelectors,
  DedicationActions,
  useSelector,
  useDispatch,
} from "../state";

export const DropZone = function({ type, slideNumber }) {
  const isMobile = useMobile();
  const donor = useSelector(DedicationsSelectors.donor);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  return (
    <div style={{ alignItems: "left", width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Upload {type}
        </Button>
      </div>
      <DropzoneDialog
        dialogTitle=""
        maxFileSize={100000000}
        acceptedFiles={[`${type.toLowerCase()}/*`]}
        dropzoneText={isMobile ? "Press to select a file" : undefined}
        filesLimit={1}
        open={open}
        onClose={() => setOpen(false)}
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        onSave={e => {
          console.log("e", e[0]);
          var reader = new FileReader();
          reader.onload = ef => {
            let slideFile = null;
            if (e) {
              slideFile = e[0];
              slideFile.mysrc = ef.target.result;
              // for unique key that changes for the video preview
              console.log(
                "donor[`slide_$_file`] ",
                donor[`slide_${slideNumber}_file`],
              );
              slideFile.key =
                donor[`slide_${slideNumber}_file`] &&
                donor[`slide_${slideNumber}_file`].key
                  ? Number(donor[`slide_${slideNumber}_file`].key) + 1
                  : 0;
            }
            dispatch(
              DedicationActions.setDonor({
                ...donor,
                [`slide_${slideNumber}_file`]: slideFile,
              }),
            );
            setOpen(false);
          };
          reader.readAsDataURL(e[0]);
        }}
      />
    </div>
  );
};
