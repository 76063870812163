import React from "react";
import { MenuItem, TextField } from "@material-ui/core";
// Local
import { countries } from "../../assets/data/countries";

const menuItems = countries.map(country => (
  <MenuItem key={country.abbr} value={country.abbr}>
    {country.name}
  </MenuItem>
));

/**
 * @typedef {import("@material-ui/core").SelectProps} SelectProps
 *
 * @typedef {object} SelectCountryProps
 * @property {string} [label]
 *
 * @param {SelectProps & SelectCountryProps} props
 */
function _SelectCountry({
  displayEmpty,
  children,
  className,
  labelClassName,
  label = "Country",
  name = "country",
  onChange,
  value,
  style,
  error,
  autoComplete,
  ...props
}) {
  return (
    <TextField
      InputLabelProps={{
        shrink: true,
      }}
      label="Country"
      name={name}
      onChange={onChange}
      SelectProps={{ displayEmpty: true }}
      select
      error={error}
      value={value}
      variant="outlined"
      style={style}
      autoComplete={autoComplete}
    >
      {menuItems}
    </TextField>
  );
}

export const SelectCountry = React.memo(_SelectCountry);
