import axios from "axios";

const { REACT_APP_API_URL } = process.env;

const type = {
  EMAIL_TEMPLATE_CREATE_REQUEST: "EMAIL_TEMPLATE_CREATE_REQUEST",
  EMAIL_TEMPLATE_CREATE_SUCCESS: "EMAIL_TEMPLATE_CREATE_SUCCESS",

  EMAIL_TEMPLATE_UPDATE_REQUEST: "EMAIL_TEMPLATE_UPDATE_REQUEST",
  EMAIL_TEMPLATE_UPDATE_SUCCESS: "EMAIL_TEMPLATE_UPDATE_SUCCESS",

  EMAIL_TEMPLATE_GET_REQUEST: "EMAIL_TEMPLATE_GET_REQUEST",
  EMAIL_TEMPLATE_GET_SUCCESS: "EMAIL_TEMPLATE_GET_SUCCESS",

  EMAIL_TEMPLATE_EDITOR_LOADED: "EMAIL_TEMPLATE_EDITOR_LOADED",

  EMAIL_TEMPLATE_API_ERROR: "EMAIL_TEMPLATE_API_ERROR",
};

const apiClient = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

export const EmailEditorActions = {
  type,

  createNewEmailTemplate() {
    console.log("creating template");
    return async dispatch => {
      dispatch({ type: type.EMAIL_TEMPLATE_CREATE_REQUEST });

      const results = await apiClient.post(`/email_template_editor`);

      console.log({ data: results.data });

      const { id, secret } = results.data;

      dispatch({
        type: type.EMAIL_TEMPLATE_CREATE_SUCCESS,
        payload: { id, secret },
      });
    };
  },

  updateEmailTemplate({ design, html }) {
    return async (dispatch, getState) => {
      dispatch({
        type: type.EMAIL_TEMPLATE_UPDATE_REQUEST,
        payload: { design, html },
      });

      const {
        emailEditor: { secret, id },
      } = getState();
      // const secret = "87e6bcb3-697e-487c-aecf-9e768fbde501";
      // const id = 1;

      try {
        await apiClient.put(`/email_template_editor/${id}?secret=${secret}`, {
          design,
          html,
        });

        dispatch({ type: type.EMAIL_TEMPLATE_UPDATE_SUCCESS });
      } catch (e) {
        console.log("error updating");
        dispatch({
          type: type.EMAIL_TEMPLATE_API_ERROR,
          payload: { message: e.message },
        });
      }
    };
  },

  loadExistingEmailTemplate({ id, secret }) {
    return async dispatch => {
      dispatch({
        type: type.EMAIL_TEMPLATE_GET_REQUEST,
        payload: { secret, id },
      });

      try {
        const results = await apiClient.get(
          `/email_template_editor/${id}?secret=${secret}`,
        );

        const { design, html } = results.data;

        console.log({ html });

        dispatch({
          type: type.EMAIL_TEMPLATE_GET_SUCCESS,
          payload: { design, html },
        });
      } catch (e) {
        if (e.response.status === 404) {
          dispatch({
            type: type.EMAIL_TEMPLATE_API_ERROR,
            payload: {
              message:
                "Invalid URL. This can happen if the url is not complete or the template has been deleted.",
            },
          });
        } else {
          dispatch({
            type: type.EMAIL_TEMPLATE_API_ERROR,
            payload: { message: e.message },
          });
        }
      }
    };
  },
};
