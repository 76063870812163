export const DedicationsSelectors = {
  items(state) {
    return state.dedications?.items ?? [];
  },
  getLastItem(state) {
    return state.dedications.items[state.dedications.items.length - 1];
  },
  allDedications(state) {
    return state.dedications.items;
  },
  allFilledDedications(state) {
    return state.dedications.items.filter(
      i => i.dedication_type_id && i.name && i.day,
    );
  },
  currency(state) {
    return state.dedications.currency;
  },
  donor(state) {
    return state.dedications.donor;
  },
  allDedicationsExceptActive(state) {
    return state.dedications.items.slice(0, [
      state.dedications.items.length - 1,
    ]);
  },
  stripeOpen(state) {
    return state.dedications.stripeOpen;
  },
  donateSuccess(state) {
    return state.dedications.donateSuccess;
  },
  lectureAlreadySponsored(state) {
    return state.dedications.lectureAlreadySponsored;
  },
};
