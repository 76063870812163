import { UIActions } from "./actions";
/**
 * UI state (**NOT** persisted).
 * @example
 * {
 *    loading: false,
 *    notification: { duration: number, message: string, variant: "" | "error" }
 * }
 */
export const UIState = {
  name: "ui",
  persist: false,
  defaults: {
    loading: false,
    headerText: "Daily Dose Dedications",
    displayTextItems: {
      informationalHeading:
        "Get your dedications seen by many thousands of people.<br/>It's only $52 per dedication and you can use Ma'aser.",
    },
    textFieldsTouched: [],
    showErrorFields: false,
  },
  handlers: {
    [UIActions.type.UI_SET_HEADER](state, { value }) {
      return {
        ...state,
        headerText: value,
      };
    },
    [UIActions.type.UI_SET_DISPLAY_TEXT_ITEMS](state, { obj }) {
      console.log("{ obj }", { obj });
      return {
        ...state,
        displayTextItems: { ...state.displayTextItems, ...obj },
      };
    },
    [UIActions.type.UI_LOADING_SET](state, { value }) {
      return {
        ...state,
        loading: value,
      };
    },
    [UIActions.type.UI_NOTIFICATION_HIDE](state) {
      return {
        ...state,
        notification: undefined,
      };
    },
    [UIActions.type.UI_NOTIFICATION_SHOW](state, { type, ...notification }) {
      return {
        ...state,
        notification,
      };
    },
  },
};
